.a-di-vind {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //height: calc(100vh + 80px);
  display: flex;
  z-index: 999999;
  background-color: rgba(0,0,0,.8);
  & .a-di-non-vind {
    margin: auto;
    max-width: 60vw;
    min-width: 60vw;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    height: auto;
    @media (max-width: 1024px) {
      max-width: unset;
    }
    @media (max-width: 600px) {
      max-height: 100vh;
      overflow-y: scroll;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
    & .a-di-top {
      background-color: #011687;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      &--wrap {
        flex-wrap: wrap;
      }

      & .dr-di-l {
        font-size: 26px;
        color: white;
        font-weight: 600;
      }
      & .a-di-r {
        & a {
          color: white;
          text-decoration: none;
        }
      }
      .course-order-title {
        width: 100%;
        font-size: 20px;
        color: #fff;
      }
    }
    & .a-di-butt {
      padding: 20px 20px 20px;
    }
  }
}

.st-popup {
  &__descr {
    color: #a3a3b7;
    font-size: 10px;
    margin-bottom: 20px;
  }
}

.st-popup-login {
  &__wrapper {
    padding: 10px;
    @media (min-width: 1240px) {
      padding: 30px;
    }
    & .tabs {
      margin-bottom: 20px;
      & li {
        display: inline-block;
        & a{
          font-size: 26px;
          color: #11145c;
          font-weight: 600;
          opacity: .5;
          &.active {
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__wrapper-rs {
    display: none;
    background: #000587 url('#{$path-img}/new/tmp/popup-logo.png') center bottom no-repeat;
    padding: 10px;
    color: white;
    font-size: 16px;
    @media (min-width: 1240px) {
      padding: 30px;
    }
    @media (min-width: 768px) {
      display: block;
    }
    & span {
      display: block;
      font-size: 20px;
    }
  }
  &__link {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 20px;
    color: #11145c;
    margin-bottom: 20px;
  }
  &__social {
    display: flex;
  }
  &__social-item {
    width: 50px;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    @media (max-width: 990px) {
      flex-direction: column;
    }
  }
  &__wrap {
    @media (min-width: 990px) {
      width: 45%;
    }
  }
}



.form {
  padding-bottom: 10px;
  & .row {
    //max-width: 600px;
    display: flex;
    margin-bottom: 10px;
    font-size: rems(16);
    text-align: left;
    align-items: flex-end;
    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0;
    }
    & h4 {
      font-size: 20px;
      color: #011687;
    }
    & > label {
      width: 50%;
      margin-right: 2%;
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }
    }
    & > input,
    & > select {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    & > textarea {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    & label {
      padding: 10px 0 0 0;
    }
    & input  {
      padding: 10px;
      font-size: 16px;
      color: #11145c;
      border-radius: 4px;
      border: 1px solid #000587;
    }
    & input[type = radio] {
      display: none;
      &+label {
        display: inline-block;
        padding-left: 20px;
        margin-bottom: 10px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          border: 1px solid #011687;
          border-radius: 50%;
          left: 0;
          top: 0;
        }
      }
      &:checked {
        &+label {
          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #011687;
            left: 4px;
            top: 4px;
          }
        }
      }
    }
    & input[type = submit],
    & button {
      padding: 10px 30px;
      border-radius: 4px;
      color: black;
      font-weight: 600;
      background-color: #f9fc30;
      font-size: 18px;
      border: 0;
      cursor: pointer;
      width: unset;
      &:hover {
        background-color: #f9fc30 + 20;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    & input[type = file] {
      padding: 10px;
      font-size: rems(16);
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
    }
    & select {
      padding: 10px;
      font-size: rems(16);
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
    }
    & textarea {
      font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
      flex-grow: 1;
      padding: 10px;
      font-size: rems(14);
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
    }
    &.buttons {
      justify-content: space-around;
    }
    & .dg-vertical {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 50%;
      margin-right: 2%;
      @media (max-width: 768px) {
        width: 100%;
        margin: 0 0 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      & label,
      & input {
        width: 100%;
      }
      & label {
        padding: 0 0 4px;
        color: #11145c;
      }
      &.width-fix {
        width: 100%;
        margin-right: 0;
      }
    }
    &.wrap {
      @media (max-width: 1200px) and (min-width: 769px) {
        flex-wrap: wrap;
        & .dg-vertical {
          width: 49%;
          margin-bottom: 10px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    & .dg-col-wrap {
      width: 50%;
      flex-wrap: wrap;
      @media (max-width: 600px) {
        width: 100%;
      }
      & label {
        width: 100%;
        padding: 0;
      }
      & > label {
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 500;
      }
      & span {
        font-size: rems(14);
      }
      &.dg-width-fix {
        width: 100%;
      }
      &.dg-font-fix span label {
        font-size: rems(14);
      }
    }
    .dg-restore {
      margin-left: 20px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      & input[not = checkbox] {
        width: 100%;
      }
      & input[type = checkbox],
      & input[type = checkbox] {
        width: unset;
      }
    }
  }
  .dg-btn-row {
    margin-bottom: 10px;
    font-size: rems(16);
    text-align: left;
    display: flex;
    justify-content: space-around;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    & input {
      width: 100%;
      @media (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }
    & .a-mail-log,
    & .a-parol-log {
      padding: 10px;
      font-size: rems(16);
      color: #999;
      border-radius: 6px;
      border: 1px solid #ccc;
      margin-right: 3%;
    }
    & .button {
      padding: 10px 30px;
      border-radius: 4px;
      font-weight: 600;
      color: black;
      background-color: #ffff44;
      font-size: 18px;
      border: 0;
      cursor: pointer;
    }
    &.fix {
      & input[type = button] {
        max-width: 400px;
      }
    }
  }
  &__block {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #ccc;
    & .dg-small {
      display: block;
      font-size: rems(12);
      color: #666;
    }
  }
}

.st-popup {
  &__block {
    margin-bottom: 14px;
    padding-bottom: 0;
    border: none;
  }
  &__descr-info {
    color: red;
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
  }
  &__title {
    &--margin {
      margin-bottom: 4px;
    }
  }
}