$path-img: '../img/';

$color-main-blue: #2a2a32;
$color-link: #315CAB;//
$color-link-hover: #0A7FBB;//

@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

* {
  box-sizing: border-box;
}

h2, h3, h4, h5, h6, p {
  margin: 0;
  &:not(:last-child) {
    margin: 0 0 10px;
  }
}

input:focus,
textarea:focus,
button:focus,
a:focus {
  outline-color: rgba(255,255,255,.01);
}

ul,ol {
  padding: 0;
  margin: 0;
  list-style: none;
}


a {
  color: #0066ff;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #aaa;
}

::-moz-placeholder {
  color: #aaa;
}

:-moz-placeholder,
:-ms-input-placeholder {
  color: #aaa;
}

input[type = 'search']::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}


html {
  font-family: 'Source Sans Pro', sans-serif;
  color: #2a2a32;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}


body {
  position: relative;
  margin: 0;
  background-color: #f2f2f2;
}

input {
  vertical-align: top;
}

.radiobutton {
  label {
    padding: 10px 0 0 0;
  }
  input  {
    padding: 10px;
    font-size: 16px;
    color: #11145c;
    border-radius: 4px;
    border: 1px solid #000587;
  }
  input[type = radio] {
    display: none;
    &+label {
      display: inline-block;
      padding-top: 0;
      padding-left: 20px;
      margin-bottom: 5px;
      font-weight: 500;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border: 1px solid #011687;
        border-radius: 50%;
        left: 0;
        top: 0;
      }
    }
    &:checked {
      &+label {
        &::after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #011687;
          left: 4px;
          top: 4px;
        }
      }
    }
  }
}

.dg-right-message,
.dg-left-message {
  border-radius: 6px;
  padding: 10px;
  background-color: white;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
  &__header {
    font-size: rems(18);
    margin-bottom: 10px;
    &.center {
      text-align: center;
    }
  }
  &__text {
    font-size: rems(22);
    font-weight: 600;
    margin-bottom: 10px;
    &.center {
      text-align: center;
    }
  }
  &__footer {
    font-size: rems(16);
    &.center {
      text-align: center;
    }
  }
  &__header,
  &__text,
  &__footer {
    span {
      display: block;
    }
  }
}

.a-x {
  font-size: rems(40);
}

.dg-reg-error {
  color: red;
}

.container {
  .dg-aside-left {
    margin-right: 2%;
    min-width: 23%;
    max-width: 23%;
    @media (max-width: 1024px) {
      margin-right: 0;
      min-width: unset;
      max-width: unset;
    }
    &__small {
      margin-right: 2%;
      min-width: 18%;
      max-width: 18%;
    }
    &__filter {
      border-radius: 6px;
      background-color: white;
      margin-bottom: 20px;
      padding: 20px;
    }
  }
  .dg-main {
    min-width: 50%;
    flex-grow: 2;
    @media (max-width: 1024px) {

      flex-grow: unset;
    }

    &__total-found {
      margin-bottom: 10px;
    }
  }
  .dg-aside-right {
    margin-left: 2%;
    min-width: 23%;
    max-width: 23%;
    @media (max-width: 1024px) {
      order: 3;
      max-width: unset;
      min-width: unset;
      margin-left: 0;
    }
    &__small {
      margin-left: 2%;
      min-width: 18%;
      max-width: 18%;
      @media (max-width: 1024px) {
        max-width: unset;
        min-width: unset;
        margin-left: 0;
      }
    }
  }
}

.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}


.dg-bottom-message {
  background-color: #2a2a32;
  padding: 10px 0 20px;
  .dg-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  &__text {
    font-size: rems(28);
    font-weight: 300;
    color: white;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
  &__link {
    display: inline-block;
    background-color: #ff4f28;
    color: white;
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 2px;
    font-size: rems(22);
    text-align: center;
    @media (max-width: 768px) and (min-width: 600px) {
      margin-left: 10px;
    }
    &:hover {
      background-color: #ff4f28+30;
    }
  }
}

h3 {
  font-size: rems(26);
  margin: 0 0 20px;
  text-align: center;
}

input.st-input {
  padding: 10px;
  font-size: 1rem;
  color: #11145c;
  border-radius: 4px;
  border: 1px solid #000587;
  &.w100 {
    width: 100%;
  }
}

label.st-input {
  font-size: 18px;
  color: #11145c;
  &.w100 {
    width: 100%;
  }
}

.block .errorSummary {
  color: black;
}

.st{
  &-mt {
    &__auto {
      margin-top: auto;
    }
    &__null {
      margin-top: 0;
    }
  }
  &-mr {
    &__10 {
      margin-right: 10px;
    }
    &__20 {
      margin-right: 20px;
    }
    &__auto {
      margin-right: auto;
    }
    &__null {
      margin-right: 0;
    }
  }
  &-mb {
    &__10 {
      margin-bottom: 10px;
    }
    &__14 {
      margin-bottom: 14px;
    }
    &__20 {
      margin-bottom: 20px;
    }
    &__40 {
      margin-bottom: 40px;
    }
    &__auto {
      margin-bottom: auto;
    }
    &__null {
      margin-bottom: 0;
    }
  }
  &-ml {
    &__10 {
      margin-left: 10px;
    }
    &__20 {
      margin-left: 20px;
    }
    &__auto {
      margin-left: auto;
    }
    &__null {
      margin-left: 0;
    }
  }
  &-pr {
    &__10 {
      padding-right: 10px;
    }
    &__20 {
      padding-right: 20px;
    }
  }
  @media (max-width: 991px) {
    &-wmt {
      &__auto {
        margin-top: auto;
      }
      &__null {
        margin-top: 0;
      }
    }
    &-wmr {
      &__10 {
        margin-right: 10px;
      }
      &__20 {
        margin-right: 20px;
      }
      &__auto {
        margin-right: auto;
      }
      &__null {
        margin-right: 0;
      }
    }
    &-wmb {
      &__10 {
        margin-bottom: 10px;
      }
      &__14 {
        margin-bottom: 14px;
      }
      &__20 {
        margin-bottom: 20px;
      }
      &__auto {
        margin-bottom: auto;
      }
      &__null {
        margin-bottom: 0;
      }
    }
    &-wml {
      &__10 {
        margin-left: 10px;
      }
      &__20 {
        margin-left: 20px;
      }
      &__auto {
        margin-left: auto;
      }
      &__null {
        margin-left: 0;
      }
    }
  }
}

.st-button {
  border: none;
  display: inline-block;
  border-radius: 4px;
  padding: 12px 30px;
  cursor: pointer;
  &.w100 {
    width: 100%;
  }
  &.ta-center {
    text-align: center;
  }
  &.st-yellow {
    background-color: #f9fc30;
    color: black;
    &:hover {
      background-color: #f9fc30 + 20;
    }
  }
}

.dg-small-font {
  font-size: rems(12);
  line-height: 1.3;
  display: block;
  margin-bottom: 20px;
  color: #aaa;
}

form {
  .errorSummary {
    background-color: rgba(255, 0, 0, 0.11);
    border-color: rgba(255, 0, 0, 0.25);
    padding: 10px;
    margin-bottom: 20px;
    & p {
      font-weight: 600;
      font-size: rems(16);
      margin-bottom: 10px;
    }
    & ul {
      margin: 0;
      font-size: rems(14);
      padding-left: 30px;
      text-align: left;
    }
  }
}


.st-descr-info {
  color: red;
  display: block;
  font-size: 12px;
  margin-bottom: 6px;
}

.dg-tab {
  .tabs {
    display: flex;
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    justify-content: flex-start;
    border-bottom: 1px solid #aaa;
    & li {
      & a {
        color: #011687;
        text-decoration: none;
        display: block;
        padding: 10px 20px;
        font-weight: 600;
        border-bottom: 6px solid transparent;
        &.active {
          border-bottom-color: #011687;
        }
      }

    }
  }
}

//.dg-link {
//  color: #34404b;
//  text-decoration: underline;
//  &:hover {
//    text-decoration: none;
//  }
//  &__icon {
//    &.document:before {
//      content: '';
//      display: inline-block;
//      background: url('#{$path-img}icons/2/document.svg') no-repeat;
//      width: 20px;
//      height: 18px;
//      position: relative;
//      top: 3px;
//    }
//  }
//}

//.dg-height {
//  min-height: calc(100vh - 229px);
//  padding-top: 82px;
//  @media (max-width: 1024px) and (min-width: 601px) {
//    padding-top: 62px;
//  }
//  @media (max-width: 600px) {
//    min-height: unset;
//    padding-top: 0;
//  }
//}



//.tabs-login {
//  position: relative;
//  .dr-login-reg {
//    cursor: pointer;
//    &:after {
//      content: '×';
//      display: block;
//      position: absolute;
//      top: 3px;
//      right: 20px;
//      font-size: rems(40);
//      color: white;
//      font-weight: 100;
//      @media (max-width: 768px) {
//        right: 1%;
//      }
//    }
//  }
//  & .tabs {
//    display: flex;
//    list-style: none;
//    margin: 0 0 30px;
//    padding: 10px 20px;
//    background-color: #2a2a32;
//    justify-content: flex-start;
//    & li {
//      & a {
//        color: white;
//        text-decoration: none;
//        display: block;
//        padding: 10px 20px;
//      }
//      & .active {
//        color: white;
//        border-radius: 10px;
//        background-color: #536b80;
//        position: relative;
//        &:after {
//          content: '';
//          position: absolute;
//          display: block;
//          bottom: -26px;
//          left: 50%;
//          transform: translateX(-50%);
//          border-top: 16px solid #2a2a32;
//          border-left: 16px solid transparent;
//          border-right: 16px solid transparent;
//        }
//      }
//    }
//    @media (max-width: 480px) {
//      padding: 20px;
//      & li {
//        a {
//          padding: 10px;
//          font-size: rems(14);
//        }
//        & .active {
//          max-width: 75%;
//        }
//      }
//    }
//  }
//  & .dr-registration-dlg {
//    padding: 0 20px 20px;
//    & p {
//      color: #555;
//      font-size: rems(12);
//      margin-bottom: 20px;
//      text-align: left;
//    }
//    & .dg-btn-reg {
//      padding: 10px 30px;
//      margin-bottom: 20px;
//      border-radius: 6px;
//      color: white;
//      background-color: #35678c;
//      font-size: rems(18);
//      border: 0;
//      cursor: pointer;
//      &:hover {
//        background-color: #35678c+20;
//      }
//    }
//  }
//  & .a-form-login {
//    padding: 0 20px;
//    //max-width: 600px;
//    & .dg-login-title {
//      font-size: rems(22);
//      font-weight: 400;
//      margin: 0 0 20px;
//      text-align: center;
//    }
//    & .dg-forgot-pass {
//      text-align: right;
//      margin: 0 0 20px;
//      &__link {
//        font-size: rems(14);
//      }
//    }
//  }
//}
//


//.dg-sm {
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//  &__title {
//    font-size: rems(16);
//    color: #546673;
//    margin-right: 2%;
//    padding: 14px 0;
//  }
//  &__wrapper {
//    width: 50px;
//    margin-right: 2%;
//  }
//  @media (max-width: 480px) {
//    justify-content: space-around;
//    &__title {
//      display: none;
//    }
//  }
//}

//.dg-transparent-bg {
//  padding: 40px 0 50px;
//  @media (max-width: 600px) {
//    padding: 40px 0 0;
//  }
//  & h1 {
//    color: white;
//    text-align: center;
//    margin: 0 0 40px;
//    @media (max-width: 480px) {
//      font-size: 1.5em;
//      font-weight: 800;
//    }
//  }
//  & .dg-teaser {
//    display: flex;
//    justify-content: space-between;
//    padding: 30px 0;
//    &__item {
//      width: 20%;
//      padding: 0 10px;
//      font-size: rems(16);
//      display: flex;
//      flex-direction: column;
//      & p {
//        text-align: center;
//        margin: auto;
//      }
//      &--img {
//        width: 50px;
//        height: 50px;
//        display: block;
//        margin: 0 auto auto;
//      }
//      &:first-child {
//        padding-left: 0;
//      }
//      &:last-child {
//        padding-right: 0;
//      }
//    }
//  }
//  & .dg-triggers {
//    display: flex;
//    justify-content: space-around;
//    @media (max-width: 640px) {
//      flex-direction: column;
//      align-items: center;
//    }
//    &__item {
//      text-align: center;
//      padding: 20px;
//      border-radius: 16px;
//      border: 1.5px solid rgba(255,255,255,.2);
//      //background-color: #445d73;
//      width: 40%;
//      min-height: 200px;
//      display: flex;
//      flex-direction: column;
//      justify-content: space-between;
//      & p {
//        color: white;
//        font-size: rems(20);
//        font-weight: 600;
//        margin: 0 0 20px;
//      }
//      &--wrapper {
//        display: flex;
//        flex-direction: column;
//      }
//      &--value {
//        color: #eee;
//        margin-bottom: 20px;
//      }
//      &--link {
//        padding: 10px 30px;
//        background-color: #ff8f07;
//        min-width: 40%;
//        margin: 0 auto;
//        border-radius: 4px;
//        color: white;
//        text-decoration: none;
//        font-weight: 600;
//        &:hover {
//          background-color: #ff8f07+20;
//        }
//      }
//      @media (max-width: 640px) {
//        width: 90%;
//        &:first-child {
//          margin-bottom: 40px;
//        }
//      }
//    }
//  }
//}
//
//.dg-top-bg {
//  background: url('#{$path-img}tmp/bg_header.jpg') no-repeat;
//  background-size: cover;
//  background-position: bottom;
//  padding-bottom: 40px;
//}

//.dg-white-bg {
//  background-color: white;
//  padding: 40px 0 50px;
//  @media (max-width: 1024px) {
//    padding: 40px 0 30px;
//  }
//}

//.st_slider{
//  position: relative;
//  display: flex;
//  justify-content: center;
//  padding: 0 50px;
//  overflow: hidden;
//  & .carousel-control{
//    position: absolute;
//    top: 50%;
//    transform: translateY(-50%);
//    width: 50px;
//    height: 50px;
//    background: url('#{$path-img}tmp/next2.svg') no-repeat;
//    &.prew {
//      transform: translateY(-50%) rotate(180deg);
//      left: 0;
//    }
//    &.next {
//      right: 0;
//    }
//  }
//  & img {
//    display: block;
//    margin: auto;
//  }
//  .carousel-inner {
//    position: relative;
//    overflow: hidden;
//    width: 100%;
//
//    > .item {
//      display: none;
//      position: relative;
//      transition: 0.6s ease-in-out;
//
//      > img,
//      > a > img {
//        line-height: 1;
//      }
//
//      @media all and (transform-3d), (-webkit-transform-3d) {
//        transition: 0.6s ease-in-out;
//        backface-visibility: hidden;
//        perspective: 1000px;
//
//        &.next,
//        &.active.right {
//          transform: translate3d(100%, 0, 0);
//          left: 0;
//        }
//        &.prev,
//        &.active.left {
//          transform: translate3d(-100%, 0, 0);
//          left: 0;
//        }
//        &.next.left,
//        &.prev.right,
//        &.active {
//          transform: translate3d(0, 0, 0);
//          left: 0;
//        }
//      }
//    }
//
//    > .active,
//    > .next,
//    > .prev {
//      display: block;
//    }
//
//    > .active {
//      left: 0;
//    }
//
//    > .next,
//    > .prev {
//      position: absolute;
//      top: 0;
//      width: 100%;
//    }
//
//    > .next {
//      left: 100%;
//    }
//    > .prev {
//      left: -100%;
//    }
//    > .next.left,
//    > .prev.right {
//      left: 0;
//    }
//
//    > .active.left {
//      left: -100%;
//    }
//    > .active.right {
//      left: 100%;
//    }
//  }
//}

//.dg-slider {
//  &__title {
//    display: flex;
//    margin: 0 0 30px;
//    align-items: center;
//    @media (max-width: 768px) {
//      flex-wrap: wrap;
//      justify-content: center;
//    }
//    & h2 {
//      width: 100%;
//      padding-left: 10%;
//      margin: 0;
//      @media (max-width: 768px) {
//        padding: 0 0 20px;
//      }
//    }
//    &--link {
//      width: 15%;
//      text-align: right;
//      @media (max-width: 768px) {
//        width: unset;
//      }
//      & a {
//        border: 1px solid #ccc;
//        border-radius: 20px;
//        display: inline-block;
//        padding: 6px 10px;
//        color: #aaa;
//        text-decoration: none;
//        text-align: center;
//        &:hover {
//          color: white;
//          background-color: #aaa;
//          border-color: #aaa;
//        }
//      }
//    }
//  }
//  &__wrapper {
//    display: flex;
//    justify-content: space-between;
//    margin: 0 0 60px;
//    @media (max-width: 1024px) {
//      margin: 0 0 20px;
//    }
//    @media (max-width: 768px) and (min-width: 480px) {
//      flex-wrap: wrap;
//    }
//    @media (max-width: 480px) {
//      flex-direction: column;
//    }
//    & .dg-course {
//      width: 23%;
//      border-radius: 4px;
//      overflow: hidden;
//      box-shadow: 0 2px 6px rgba(0,0,0,.3);
//      color: #2a2a32;
//      text-decoration: none;
//      &:hover {
//        text-decoration: underline;
//        box-shadow: 0 2px 12px rgba(0,0,0,.4);
//      }
//      @media (max-width: 768px) and (min-width: 480px) {
//        width: 49%;
//        margin-bottom: 2%;
//      }
//      @media (max-width: 480px) {
//        width: 100%;
//        margin-bottom: 4%;
//      }
//      &__description {
//        padding: 10px 10px 20px;
//        font-size: rems(14);
//        &--title {
//          font-weight: 600;
//
//        }
//      }
//    }
//  }
//}
/*
.dg-system-message {
  background-color: #fcffe6;
  &__wrapper {
    max-width: 1260px;
    padding: 20px 10px;
    margin: 0 auto;
  }
  &__inner {
    position: relative;
    &--list {
      margin: 0;
      padding: 0 40px;
      & a {
        color: #1277d4;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &--close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background-color: #ffdada;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      & i {
        width: 20px;
        height: 20px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          background-color: #ffb3b3;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 15px;
          background-color: white;
          transform: rotate(45deg);
          position: relative;
          top: 2px;
          left: 9px;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 2px;
          height: 15px;
          background-color: white;
          transform: rotate(-45deg);
          position: relative;
          top: 2px;
          left: 7px;
        }
      }
    }
  }
}
*/

//.dg-license {
//  & .dg-flex {
//    display: flex;
//    margin-bottom: 20px;
//    @media (max-width: 768px) {
//      flex-direction: column;
//    }
//  }
//  &__text {
//    max-width: 70%;
//    & h3,
//    & p {
//      margin: 0 0 20px;
//      padding: 0 100px;
//    }
//    & p {
//      line-height: 1.5;
//    }
//    @media (max-width: 768px) {
//      max-width: unset;
//      & h3,
//      & p {
//        padding: 0;
//      }
//    }
//  }
//  &__preview {
//    max-width: 30%;
//    @media (max-width: 768px) {
//      max-width: unset;
//    }
//  }
//  &__list {
//    &--item {
//      max-width: 380px;
//      &:nth-child(2) {
//        margin: 0 auto;
//      }
//      & .dg-img {
//        margin-bottom: 20px;
//      }
//    }
//  }
//}

//.dg-advantages {
//  max-width: 1000px;
//  margin: 0 auto;
//  @media (max-width: 768px) {
//    overflow-x: scroll;
//  }
//  &__tips {
//    display: flex;
//    justify-content: flex-end;
//    margin-bottom: 20px;
//    @media (max-width: 768px) {
//      min-width: 748px;
//    }
//    &--tradition,
//    &--remote {
//      font-size: rems(12);
//      text-align: center;
//    }
//    &--tradition {
//      width: 10%;
//      color: #999;
//      margin-right: 2px;
//      //@media (max-width: 768px) {
//      //  width: unset;
//      //  margin-right: 10px;
//      //  padding: 10px;
//      //  background-color: #f0f0f0;
//      //  color: #a8a8a8;
//      //  font-weight: 600;
//      //}
//    }
//    &--remote {
//      width: 15%;
//      color: #333;
//      margin-right: 2.5%;
//      //@media (max-width: 768px) {
//      //  width: unset;
//      //  margin-right: 10px;
//      //  padding: 10px;
//      //  background-color: #ff9200;
//      //  color: white;
//      //  font-weight: 600;
//      //}
//    }
//  }
//  &__list {
//    margin: 0 0 20px;
//    padding: 0;
//    list-style: none;
//    @media (max-width: 768px) {
//      min-width: 748px;
//    }
//  }
//  &--item {
//    display: flex;
//    border-bottom: 1px solid #ddd;
//    align-items: center;
//    margin-bottom: 10px;
//    padding-bottom: 10px;
//    &:last-child {
//      border: none;
//    }
//    & .text {
//      width: 80%;
//      padding-right: 10px;
//    }
//    & .yes,
//    & .no {
//      padding: 10px 16px;
//      border-radius: 50%;
//      position: relative;
//      z-index: 1;
//      &::before {
//        content: '';
//        position: absolute;
//        left: 50%;
//        top: 50%;
//        width: 2rem;
//        height: 2rem;
//        background-color: black;
//        transform: translate(-50%, -50%);
//        border-radius: 50%;
//        z-index: -1;
//      }
//    }
//    & .yes {
//      color: white;
//      margin-right: 8%;
//      &::before {
//        background-color: #ff9200;
//      }
//    }
//    & .no {
//      color: #a8a8a8;
//      margin-right: 8%;
//      &::before {
//        background-color: #f0f0f0;
//      }
//    }
//    @media (max-width: 860px) {
//      margin-bottom: 5px;
//      padding-bottom: 5px;
//      & .yes,
//      & .no {
//        margin-right: 7%;
//      }
//    }
//  }
//}

//.dg-course {
//  &__item {
//    border-radius: 6px;
//    background-color: white;
//    margin-bottom: 20px;
//    padding: 10px;
//    display: flex;
//    position: relative;
//    @media (max-width: 768px) {
//      flex-direction: column;
//    }
//    &:before {
//      content: '';
//      position: absolute;
//      top: 0;
//      right: 0;
//      bottom: 0;
//      left: 0;
//      box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
//      z-index: -1;
//    }
//    &--ls {
//      width: 25%;
//      margin-right: 10px;
//      @media (max-width: 768px) {
//        width: unset;
//        margin-right: 0;
//        margin-bottom: 10px;
//      }
//    }
//    &--rs {
//      width: calc(75% - 10px);
//      display: flex;
//      flex-direction: column;
//      justify-content: space-between;
//      @media (max-width: 768px) {
//        width: unset;
//      }
//    }
//  }
//  &__header {
//    font-size: rems(14);
//    color: #aaa;
//  }
//  &__title {
//    font-size: rems(18);
//    font-weight: 500;
//    & a {
//      color: #2a2a32;
//      text-decoration: none;
//      &:hover {
//        text-decoration: underline;
//      }
//    }
//  }
//  &__footer {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    font-size: rems(16);
//    margin-bottom: 10px;
//    @media (max-width: 768px) {
//      flex-wrap: wrap;
//      margin-bottom: 0;
//    }
//    &--time {
//      color: #aaa;
//    }
//    &--price {
//      color: #aaa;
//      margin: 0 20px;
//      flex-grow: 3;
//      text-align: right;
//      @media (max-width: 768px) {
//        flex-grow: unset;
//        margin: 0;
//      }
//    }
//    &--button {
//      @media (max-width: 768px) {
//        width: 100%;
//        padding-top: 10px;
//      }
//      & .dg-button {
//        display: inline-block;
//        margin: 0 auto;
//        padding: 10px 30px;
//        background-color: #ff8f07;
//        border-radius: 4px;
//        color: white;
//        text-decoration: none;
//        font-weight: 600;
//        @media (max-width: 768px) {
//          display: inline-block;
//          width: 100%;
//          text-align: center;
//        }
//        &:hover {
//          background-color: #ff8f07+30;
//        }
//      }
//    }
//  }
//}

//.dg-bread-crumbs {
//  max-width: 1260px;
//  padding: 10px 0 0;
//  margin: 0 auto 10px;
//  font-size: rems(12);
//  color: #aaa;
//  //margin-bottom: 10px;
//  a {
//    color: #1277d4;
//    &:hover {
//      text-decoration: none;
//    }
//  }
//  &.dg-hide {
//    display: none;
//  }
//}

//.dg-page-title {
//  color: #546673;
//}


.pager {
  margin-bottom: 20px;
  & ul.yiiPager {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0 20px;
    & li {
      margin: 0 2px;
      a {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        color: #546673;
        border: 1px solid #dde2e5;
        background-color: white;
        border-radius: 50%;
        text-decoration: none;
        &:hover {
          background-color: #e7e7f4;
          color: #151515;
          border-color: #e7e7f4;
        }

      }
      &.selected a {
        background-color: #e7e7f4;
        color: #151515;
        border-color: #e7e7f4;
      }
    }
  }
  & .first,
  & .last {
    @media (max-width: 480px) {
      display: none;
    }
  }
}


.dg-center-box {
  max-width: 50vw;
  //margin: 0 auto;
  padding: 10px 20px 2px;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
  @media (max-width: 1024px) {
    max-width: unset;
  }
  &.bg-white {
    background-color: white;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
      z-index: -1;
    }
    .st_active-mail{
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }
}

.dg-bread-crumbs {
  display: none;
}

.dg-teaser {
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
  line-height: 1.5;
  &__title {
    display: block;
    font-size: rems(16);
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__body {
    font-size: rems(16);
    display: block;
  }
  &__footer {
    font-size: rems(16);
    display: block;
    margin-bottom: 20px;
  }
  &__tel {
    display: inline-block;
    font-weight: 600;
    font-size: rems(16);
    color: inherit;
    text-decoration: none;
  }
  &__btn {
    margin: 0 auto;
    padding: 6px 20px;
    color: #555;
    background-color: #eee;
    border: 1px solid #ddd;
    text-decoration: none;
    border-radius: 6px;
    &:hover {
      background-color: #ddd;
      border-color: #ccc;
    }
    &.icon_letter:before {
      content: '';
      display: inline-block;
      width: 17px;
      height: 12px;
      margin-right: 8px;
      background: url('#{$path-img}icons/mail-btn.svg') no-repeat;
    }
  }
}

//.dg-filter {
//  font-size: rems(14);
//  &__hider {
//    background-color: #eee;
//    padding: 10px;
//    margin: 0 -10px 0;
//    cursor: pointer;
//    @media (min-width: 1025px) {
//      display: none;
//    }
//    &:after {
//      content: '';
//      margin-left: 4px;
//      display: inline-block;
//      width: 18px;
//      height: 18px;
//      background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
//      background-size: contain;
//      position: relative;
//      top: 4px;
//      transform: rotate(-90deg);
//    }
//    &.rotate {
//      &:after {
//        transform: rotate(0);
//      }
//    }
//  }
//  & .dg-hide {
//    @media (max-width: 1024px) {
//      display: none;
//    }
//  }
//  p {
//    font-weight: 600;
//  }
//  &__list {
//    display: flex;
//    flex-flow: row wrap;
//    margin: 0;
//    padding: 0;
//    list-style-type: none;
//    & li {
//      display: flex;
//      flex-basis: 100%;
//      margin-bottom: 6px;
//      @media (max-width: 1024px) and (min-width: 601px) {
//        margin-right: 10px;
//      }
//      @media (max-width: 600px){
//        margin-right: 10px;
//      }
//      & span {
//        white-space: normal;
//      }
//      & input {
//        margin-right: 6px;
//      }
//    }
//    &.full-width li {
//      flex-basis: 100%;
//    }
//  }
//  &__section {
//    margin-bottom: 20px;
//    &:last-child {
//      margin-bottom: 0;
//    }
//    &--cost {
//      display: flex;
//      justify-content: space-between;
//      & .input {
//        width: 48%;
//        padding: 10px 2px 10px 10px;
//        border-radius: 4px;
//        border: 1px solid #ccc;
//      }
//    }
//    &--btn-wrapper {
//      & a {
//        display: block;
//        position: relative;
//        padding: 6px 20px 6px 10px;
//        border: 1px solid #ccc;
//        border-radius: 4px;
//        color: #666;
//        text-decoration: none;
//        &:hover {
//          background-color: #ccc;
//        }
//        &:after {
//          content: '';
//          position: absolute;
//          border-top: 6px solid transparent;
//          border-left: 8px solid #aaa;
//          border-bottom: 6px solid transparent;
//          right: 10px;
//          top: 50%;
//          transform: translateY(-50%);
//        }
//      }
//    }
//  }
//  &__link {
//    display: flex;
//    justify-content: space-between;
//    color: #2a2a32;
//    padding: 6px 0 6px 6px;
//    border: 1px solid #eee;
//    border-radius: 4px;
//    cursor: pointer;
//    position: relative;
//    z-index: 1;
//    &:after {
//      content: '';
//      display: inline-block;
//      border: 10px solid #eee;
//      border-top-color: transparent;
//      border-right-color: transparent;
//      border-bottom-color: transparent;
//    }
//    &--drop {
//      display: none;
//      position: absolute;
//      left: 100%;
//      transform: translateY(-50%);
//      z-index: 999;
//      box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
//      & ul {
//        margin: 0;
//        padding: 20px 20px 10px;
//        list-style-type: none;
//        color: #2a2a32;
//        display: flex;
//        flex-direction: column;
//        flex-wrap: wrap;
//        max-height: 60vh;
//        & li {
//          width: 280px;
//          margin-bottom: 10px;
//          white-space: nowrap;
//          position: relative;
//          padding: 0 20px;
//          & label {
//            white-space: normal;
//          }
//          & span {
//            position: absolute;
//            left: 0;
//            top: 0;
//            color: #aaa;
//          }
//        }
//      }
//    }
//    &:hover {
//      background-color: #ff8f07;
//      border-color: #ff8f07;
//      color: white;
//      &:after {
//        border-left-color: white;
//      }
//      & .dg-filter__link--drop {
//        display: block;
//        background-color: white;
//        border-radius: 6px;
//        &:before {
//          content: '';
//          top: 0;
//          right: 0;
//          bottom: 0;
//          left: 0;
//          z-index: -1;
//        }
//      }
//    }
//  }
//
//}





.dg-popup {
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 6px;
  color: white;
  min-width: 20vw;
  max-width: 70vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  z-index: 9999999;
  @media (max-width: 768px) {
    top: 10vh;
    left: 0;
    transform: translateX(0);
    min-width: unset;
    max-width: unset;
  }
  &.error {
    background-color: #af0000;
  }
  &.alert {
    background-color: rgba(0,0,0,.9);
    & a {
      color: #63cdf8;
      text-decoration: underline;
    }
  }
  & .a-dell {
    margin-left: 10px;
    font-size: rems(18);
    line-height: 1;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}



//

//
//.dg-goback {
//  display: inline-block;
//  text-decoration: none;
//  padding: 6px 10px;
//  border-radius: 30px;
//  background-color: #eaeef0;
//  font-size: rems(14);
//  box-shadow: 0 4px 11px -4px rgba(0, 0, 0, 0.2);
//  color: #435c75;
//  &:hover {
//    background-color: #ff8f07;
//    color: white;
//    text-decoration: none;
//  }
//  &:before {
//    content: '<';
//  }
//}
//
.dg-row-wrap {
  display: block;
  margin-bottom: 10px;
}
//
//.dg-course-content {
//  & h1 {
//    font-size: rems(20);
//    line-height: 1.3;
//  }
//}
//

span.required {
  color: red;
}

.small {
  font-size: rems(12);
}
//
.dg-upload-file {
  display: block;
  background-color: #f4f4f4;
  cursor: pointer;
  border-radius: 10px;
  padding: 16px;
  text-align: center;
  margin-bottom: 20px;
  border: 2px dotted #ccc;
  &:hover {
    background-color: white;
  }
  & input {
    display: none;
  }
}
//
//.dg-ord-message {
//  padding: 20px 20px 10px;
//  border: 1px solid #f4eba7;
//  border-radius: 6px;
//  background-color: #fcffe6;
//  margin-bottom: 20px;
//}
//
.dg-order {
  background-color: #f4f4f4;
  box-shadow: 0 2px 8px -3px rgba(0,0,0,.6);
  margin-bottom: 20px;
  border: 1px solid white;
  &--list {

  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    @media (max-width: 768px) {
      border-bottom: 1px solid #425c74;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      padding: 10px;
      &--number,
      &--status,
      &--date {
        margin-bottom: 10px;
      }
    }
    &--number,
    &--date {
      display: flex;
    }
    &--number strong,
    &--date span {
      margin: auto;
      @media (max-width: 480px) {
        margin: 0;
      }
    }
    &--status {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-basis: 60%;
      @media (max-width: 768px) {
        flex-direction: column;
        flex-basis: 100%;
      }
      & .dg-unpaid,
      & .dg-paid {
        margin: 0 20px;
        & span {
          &.height-fix {
            display: inline-block;
            padding: 4px 0;
          }
          &:first-child {
            display: block;
            margin-bottom: 2px;
          }
        }
      }
      & .dg-unpaid {
        color: #f75205;
        text-align: center;

        & a {
          display: inline-block;
          background-color: #f75205;
          border-radius: 6px;
          padding: 4px 10px;
          color: white;
          text-decoration: none;
        }
      }
      & .dg-paid {
        color: #6ac25a;
        position: relative;
        text-align: center;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -26px;
          width: 20px;
          height: 20px;
          background: url('#{$path-img}icons/2/paid.svg') no-repeat;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  &__body {
    & ul.tabs {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      & li {
        width: 100%;
        & a {
          display: flex;
          height: 60px;
          text-decoration: none;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          color: #2a2a32;
          text-align: center;
          @media (max-width: 768px) {
            border-right: none;
            border-color: #425c74;
          }
          & span {
            margin: auto;
          }
          &.active {
            background-color: white;
            border-top: 10px solid #425c74;
            border-bottom-color: transparent;
            @media (max-width: 768px) {
              border-bottom-color: #425c74;
              background-color: #425c74;
              border-top-width: 2px;
              color: white;
            }
          }
          border-right: 1px solid #ccc;

        }
        &:last-child a {
          border-right: none;
        }
      }
    }
    .view {
      padding: 20px 20px 20px;
      background-color: white;
      @media (max-width: 768px) {
        border-top: 1px solid #425c74;
      }
    }
    &--first-tab {

    }
  }
  &__title {
    font-weight: 600;
  }
  &__table {
    display: flex;
    font-size: rems(14);
    margin-bottom: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &--ls,
    &--rs {
      width: 100%;
      padding-top: 10px;
    }
    &--ls {
      //border-right: 1px solid #eee;
      padding-right: 10px;
      @media (max-width: 768px) {
        padding: 0;
      }
      & strong {
        white-space: nowrap;
      }
    }
    &--rs {
      padding-left: 10px;
      @media (max-width: 768px) {
        padding: 0;
      }
    }
    & table {
      width: 100%;
      border: 0;
      border-spacing: 0;
      & thead,
      & tbody {
        border: 0;
        padding: 0;
      }
      & th {
        padding: 6px;
        background-color: #f5f7f6;
        border: 1px solid #d1dbdf;
        border-right-color: transparent;
        border-bottom-color: transparent;
        font-weight: 400;
        &:last-child {
          border-right-color: #d1dbdf;
          font-weight: 600;
        }
      }
      & td {
        text-align: center;
        padding: 6px;
        border: 1px solid #d1dbdf;
        border-right-color: transparent;
        border-top-color: transparent;
        &:last-child {
          border-right-color: #d1dbdf;
          font-weight: 600;
        }
      }
    }
  }
  &__alert {
    margin-bottom: 10px;
    border: 1px solid;
    padding: 10px;
    font-size: rems(14);
    &.reject {
      color: #963203;
      font-weight: 500;
      background-color: rgba(255, 0, 0, 0.11);
      border-color: rgba(255, 0, 0, 0.25);
      & p:last-child {
        margin: 0;
      }
    }
  }
  &__step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    & .margin-fix {
      margin: 0 0 0 auto;
      min-width: 200px;
      text-align: center;
      @media (max-width: 768px) {
        margin: auto 0 auto 10px;
        min-width: unset;
      }
    }
    &--number {
      //background-color: #e8ecf0;
      //padding: 10px 16px;
      //border-radius: 50%;
      margin-right: 10px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  &__message {
    //display: flex;
    //padding: 10px;
    //border: 1px solid #f4eba7;
    //border-radius: 6px;
    //background-color: #fcffe6;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      //flex-basis: calc(100% - 52px);
      margin-bottom: 20px;
    }
    & span {
      margin: auto 0;
    }
    &--column {
      display: flex;
      flex-direction: column;
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__second-tab {
    display: flex;
    flex-direction: column;
    &--section {
      padding-top: 20px;
      border-bottom: 1px dashed #ccc;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  //&__sum-table {
  //  margin-bottom: 20px;
  //}
}
//
.dg-order-form {
  display: flex;
  flex-wrap: wrap;
  &--align-top {
    .dg-order-form__item {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }
  &__item {
    width: 49%;
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    & label {
      margin-bottom: 4px;
      position: relative;
      font-size: rems(12);
      font-weight: 600;
      letter-spacing: -0.35px;
    }
    & input {
      width: 100%;
      margin-bottom: 4px;
      padding: 10px;
      font-size: rems(16);
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
      display: block;
    }
    &:nth-child(2n) {
      margin-left: 2%;
    }
    &--btn {
      display: block;
    }
    &--info {
      display: inline-block;
      position: absolute;
      font-size: rems(10);
      font-weight: 700;
      border-radius: 50%;
      background-color: #a1aeb8;
      padding: 1px 4px 1px;
      color: #ffffff;
      top: 0;
      right: -2px;
      width: 15px;
      text-align: center;
      &:hover {
        cursor: help;
      }
      & .dg-hide-text {
        display: none;
      }
      &:hover .dg-hide-text {
        display: block;
        position: absolute;
        padding: 20px;
        border: 1px solid #f4eba7;
        border-radius: 6px;
        background-color: #fcffe6;
        color: #2a2a32;
        font-size: rems(14);
        font-weight: 400;
        width: 200px;
        box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.1);
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        @media (max-width: 1024px) {
          left: unset;
          right: 0;
          transform: translateX(0);
        }
      }
    }
  }
  &.width-fix {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    & .dg-order-form__item {
      width: 24%;
      margin: 0;
      @media (max-width: 768px) and (min-width: 481px) {
        width: 49%;
        margin: 0 0 20px;
      }
      @media (max-width: 480px) {
        width: 100%;
        margin: 0 0 20px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.dg-text-right {
  text-align: right;
}

.dg-link {
  & a {
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
  &__gray {
    font-size: rems(14);
    color: #aaa;
    text-decoration: none;
    white-space: nowrap;
  }
}
//
//.dg-flex-bs-75 {
//  flex-basis: 75%;
//}
//
.dg-order-row-btns {
  padding-top: 20px;
  & ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-type: none;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    & li {
      display: flex;
      margin: auto 0;
      color: #ccc;
      @media (max-width: 768px) {
        margin-bottom: 10px;
        text-align: center;
      }
      & a {
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      & span {
        margin: auto;
      }
    }
  }
}
//
//.dg-license-view {
//  border: 10px solid white;
//}
//
//.dg-course {
//  &__header-bg {
//    padding: 40px 0 20px;
//    background-repeat: no-repeat;
//    background-size: cover;
//    &--title {
//      color: #fffae9;
//      margin-bottom: 40px;
//      & h1 {
//        @media (max-width: 768px) {
//          font-size: 4vw;
//        }
//      }
//    }
//    &--row {
//      margin-bottom: 40px;
//      display: flex;
//      justify-content: space-between;
//      @media (max-width: 1024px) {
//        flex-wrap: wrap;
//        justify-content: center;
//      }
//      &.column {
//        @media (max-width: 768px) {
//          flex-direction: column;
//          margin: 0;
//        }
//      }
//      & .course-start,
//      & .course-period,
//      & .course-form {
//        display: flex;
//        flex-direction: column;
//        & .title {
//          color: #abb7bd;
//          font-weight: 600;
//          font-size: rems(18);
//        }
//        & .text {
//          margin-top: auto;
//          color: white;
//          font-weight: 600;
//          font-size: rems(14);
//        }
//      }
//      & .course-start {
//        width: 23%;
//        margin-right: 2%;
//        @media (max-width: 768px) {
//          width: unset;
//          margin: 0 0 10px;
//        }
//      }
//      & .course-period {
//        width: 23%;
//        margin-right: 2%;
//        @media (max-width: 768px) {
//          width: unset;
//          margin: 0 0 10px;
//        }
//      }
//      & .course-form {
//        width: 50%;
//        @media (max-width: 768px) {
//          width: unset;
//          margin-right: 0;
//        }
//      }
//    }
//    &--sale {
//      margin-right: 10px;
//      display: flex;
//      flex-direction: column;
//      flex-grow: 1;
//      @media (max-width: 768px) {
//        width: 100%;
//        margin-right: 0;
//      }
//      &__wrapper {
//        display: flex;
//        margin-bottom: 10px;
//        border-radius: 6px;
//        overflow: hidden;
//        background-color: #fffae9;
//        @media (max-width: 768px) {
//          flex-direction: column;
//        }
//        &:last-child {
//          margin-bottom: 0;
//          @media (max-width: 1024px) {
//            margin-bottom: 10px;
//          }
//        }
//      }
//      & .dg-left {
//        background-color: #f9de89;
//        display: flex;
//        flex-direction: column;
//        text-transform: uppercase;
//        font-size: 1.625rem;
//        font-weight: 800;
//        position: relative;
//        padding: 10px;
//        color: #f75205;
//        text-align: center;
//        &:after {
//          content: '';
//          position: absolute;
//          background-color: #f9de89;
//          top: 50%;
//          transform: translateY(-50%);
//          right: -12px;
//          clip-path: polygon(0 0, 40% 0, 100% 50%, 40% 100%, 0 100%);
//          width: 20px;
//          height: 100%;
//        }
//        @media (max-width: 768px) {
//          padding: 10px 20px 20px;
//          clip-path: polygon(0% 0%, 100% 0, 100% 75%, 50% 100%, 0 75%);
//        }
//        &__small{
//          display: inline-block;
//          color: #2a2a32;
//          font-size: 0.875rem;
//          font-weight: 600;
//        }
//      }
//      .dg-right {
//        display: flex;
//        align-items: center;
//        flex-grow: 1;
//        padding: 10px 10px 10px 30px;
//        font-size: 1.875rem;
//        font-weight: 600;
//        white-space: nowrap;
//        @media (max-width: 768px) {
//          flex-direction: column;
//          padding: 10px;
//        }
//        &--value{
//          padding: 0 10px;
//        }
//        &--stroke {
//          padding: 0 10px;
//          color: #e1747d;
//          text-decoration: line-through;
//          margin: auto;
//        }
//        &--sale {
//          padding: 0 10px;
//          display: flex;
//          flex-direction: column;
//          small {
//            font-size: 0.75rem;
//          }
//        }
//      }
//    }
//    &--btn {
//      display: flex;
//      min-width: 32%;
//      text-align: center;
//    }
//  }
//  &__give-doc {
//    padding: 30px 0;
//    display: flex;
//    justify-content: space-between;
//    @media (max-width: 768px) {
//      flex-direction: column;
//    }
//    &--text {
//      flex-basis: 40%;
//      padding-top: 30px;
//      & h2 {
//        text-align: center;
//        margin-bottom: 30px;
//      }
//      & p {
//        font-size: rems(20);
//        line-height: 30px;
//      }
//    }
//    &--img {
//      flex-basis: 50%;
//      margin-left: 5%;
//      position: relative;
//      z-index: 1;
//      @media (max-width: 768px) {
//        flex-basis: unset;
//        margin-left: 0;
//      }
//      & img {
//        background-color: white;
//        transition: .3s;
//        &:first-child {
//          box-shadow: 10px 10px 10px -5px rgba(0,0,0,.3);
//          z-index: 2;
//          &:hover {
//            position: relative;
//            cursor: zoom-in;
//            z-index: 100;
//            box-shadow: 20px 20px 30px -5px rgba(0,0,0,.3);
//          }
//          @media (max-width: 768px) {
//            margin-bottom: 20px;
//          }
//        }
//        &:nth-child(2) {
//          position: absolute;
//          width: 70%;
//          right: 50%;
//          top: 50%;
//          box-shadow: 10px 10px 10px -5px rgba(0,0,0,.3);
//          z-index: -1;
//          @media (max-width: 768px) {
//            position: unset;
//            width: 100%;
//          }
//          &:hover {
//            cursor: zoom-in;
//            z-index: 100;
//            box-shadow: 20px 20px 30px -5px rgba(0,0,0,.3);
//          }
//        }
//      }
//    }
//  }
//  &__about {
//    display: flex;
//    justify-content: space-between;
//    @media (max-width: 1024px) {
//      flex-direction: column;
//    }
//    & h2 {
//      width: 15%;
//      margin-right: 5%;
//      padding-top: 4px;
//      @media (max-width: 1024px) {
//        width: 100%;
//        margin-right: 0;
//      }
//      & span {
//        font-size: rems(30);
//      }
//    }
//    &--pager {
//      width: 80%;
//      margin-bottom: 20px;
//      @media (max-width: 1024px) {
//        width: 100%;
//      }
//      & .dg-section {
//        margin-bottom: 40px;
//        &__title {
//          font-size: rems(20);
//          background-color: #eee;
//          padding: 20px;
//          display: flex;
//          align-items: center;
//          @media (max-width:768px) {
//            flex-direction: column;
//          }
//        }
//        &__body {
//          padding: 20px;
//          line-height: 1.5;
//        }
//      }
//
//      & ul.tabs {
//        display: flex;
//        flex-direction: column;
//        list-style-type: none;
//        padding: 0;
//        margin: 0;
//
//        & li {
//          width: 100%;
//          & a {
//            display: block;
//            padding: 30px 20px 20px;
//            text-decoration: none;
//            color: #2a2a32;
//            font-size: rems(20);
//            & span {
//              display: block;
//              border-bottom: 1px solid #aaa;
//              padding-bottom: 10px;
//              position: relative;
//              //&:after {
//              //  content: '';
//              //  margin-left: 4px;
//              //  display: inline-block;
//              //  width: 18px;
//              //  height: 18px;
//              //  background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
//              //  background-size: contain;
//              //  position: absolute;
//              //  right: 0;
//              //  top: 50%;
//              //  transform: translateY(-50%);
//              //}
//            }
//
//          }
//          & .view {
//            margin-bottom: 20px;
//            padding: 0 20px 20px;
//            background-color: #ededed;
//            & p {
//              margin: 0;
//            }
//          }
//        }
//      }
//
//    }
//  }
//  &__privilege-bg {
//    background: #fff url('#{$path-img}tmp/back2.jpg') no-repeat;
//    background-size: cover;
//    color: white;
//    margin-bottom: 60px;
//    & .dg-main {
//      padding: 80px 0 10px;
//    }
//    & h2 {
//      text-align: center;
//      margin-bottom: 60px;
//      font-size: rems(34);
//    }
//    .dg-teasers-wrap {
//      display: flex;
//      justify-content: space-between;
//      flex-wrap: wrap;
//      &__item {
//        width: 30%;
//        padding: 0 20px;
//        margin: 0 auto 60px;
//        text-align: center;
//        font-size: rems(14);
//        @media (max-width: 768px) {
//          min-width: 280px;
//          margin: 0 auto 20px;
//        }
//        &--img {
//          max-width: 60px;
//          margin: 0 auto 20px;
//        }
//      }
//    }
//  }
//  &__program {
//    margin-bottom: 40px;
//    @media (max-width: 768px) {
//      overflow-x: scroll;
//    }
//    & h2 {
//      font-size: rems(30);
//      text-align: center;
//      margin-bottom: 30px;
//      min-width: 768px;
//    }
//    & .dg-section {
//      border-bottom: 1px solid #425c74;
//      min-width: 768px;
//      &__header {
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        list-style-type: none;
//        padding: 20px 20px 20px 34px;
//        margin: 0;
//        @media (max-width: 768px) {
//          padding: 0;
//        }
//        &--title {
//          flex-basis: 45%;
//          @media (max-width: 768px) {
//            padding: 10px;
//          }
//          & span {
//            font-size: rems(20);
//            font-weight: 600;
//          }
//        }
//        &--time,
//        &--lecture,
//        &--self,
//        &--control {
//          font-size: rems(18);
//          @media (max-width: 768px) {
//            padding: 10px;
//          }
//          &:before {
//            content: '';
//            display: inline-block;
//            width: 20px;
//            height: 20px;
//            margin-right: 4px;
//            background: url('#{$path-img}tmp/time.svg') no-repeat;
//            background-size: contain;
//            position: relative;
//            top: 2px;
//            @media (max-width: 768px) {
//              display: none;
//            }
//          }
//        }
//        &--time {
//          flex-basis: 15%;
//          text-align: center;
//        }
//        &--lecture {
//          flex-basis: 15%;
//          text-align: center;
//        }
//        &--self {
//          flex-basis: 15%;
//          text-align: center;
//        }
//        &--control {
//          flex-basis: 15%;
//          text-align: center;
//        }
//        &--hider {
//          flex-basis: 15%;
//          text-align: right;
//          @media (max-width: 768px) {
//            flex-basis: 25%;
//            padding: 10px;
//          }
//          & a {
//            text-decoration: none;
//            color: #2a2a32;
//            &:after {
//              content: '';
//              margin-left: 4px;
//              display: inline-block;
//              width: 18px;
//              height: 18px;
//              background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
//              background-size: contain;
//              position: relative;
//              top: 4px;
//            }
//          }
//        }
//      }
//      &__body {
//        & p {
//          margin-bottom: 20px;
//        }
//        &.hide {
//          display: none;
//        }
//        &.view {
//          display: block;
//        }
//        &--list {
//          display: flex;
//          justify-content: space-between;
//          align-items: center;
//          list-style-type: none;
//          padding: 20px 20px 20px 34px;
//          margin: 0;
//          @media (max-width: 768px) {
//            padding: 0;
//          }
//          &:first-child {
//            padding-top: 0;
//            &.padding-fix {
//              padding: 20px 20px 20px 34px;
//              @media (max-width: 768px) {
//                padding: 0;
//              }
//            }
//          }
//
//        }
//        &--text {
//          flex-basis: 45%;
//          font-size: rems(18);
//          @media (max-width: 768px) {
//            padding: 10px;
//          }
//        }
//        &--control {
//          flex-basis: 15%;
//          font-size: rems(18);
//          text-align: center;
//          @media (max-width: 768px) {
//            padding: 10px;
//          }
//        }
//      }
//      &.active {
//        background-color: #eceff2;
//        border-top: 1px solid transparent;
//        & ul li a:after {
//          transform: rotate(180deg);
//        }
//      }
//      &.title-dark {
//        background-color: #4e6479;
//        color: white;
//        .dg-section__header {
//          &--time:before,
//          &--lecture:before,
//          &--self:before,
//          &--control:before {
//            display: none;
//          }
//        }
//      }
//      &.title {
//        background-color: #eee;
//        border-bottom: none;
//        .dg-section__header {
//          &--time:before,
//          &--lecture:before,
//          &--self:before,
//          &--control:before {
//            display: none;
//          }
//        }
//      }
//    }
//    &.cursor {
//      .dg-section__header {
//        cursor: pointer;
//        & .dg-basis-fix {
//          flex-basis: 100%;
//        }
//      }
//      & .dg-section {
//        &.title-dark {
//          .dg-section__header {
//            cursor: default;
//          }
//        }
//      }
//    }
//  }
//  &__yellow-bg {
//    background-color: #fffae9;
//    padding: 10px 0;
//    border-top: 1px solid #f9e28c;
//    border-bottom: 1px solid #f9e28c;
//    & .dg-course__header-bg--row {
//      margin: 0;
//    }
//  }
//}
//
//.dg-comp-teaser {
//  display: flex;
//  justify-content: space-between;
//  margin-bottom: 60px;
//  @media (max-width: 768px) {
//    flex-direction: column;
//  }
//  &__title {
//    text-align: center;
//    margin-bottom: 40px;
//  }
//  &__item {
//    flex-basis: 20%;
//    @media (max-width: 768px) {
//      margin-bottom: 20px;
//    }
//    &:nth-child(1) .dg-comp-teaser__item--img {
//      background: url('#{$path-img}tmp/7.svg') no-repeat;
//      background-position: 0;
//      position: relative;
//      &:after {
//        content: '';
//        position: absolute;
//        width: 50px;
//        height: 50px;
//        background: url('#{$path-img}tmp/next.svg') no-repeat;
//        background-size: contain;
//        right: -75%;
//        top: 50%;
//        transform: translateY(-50%);
//        @media (max-width: 1024px) {
//          display: none;
//        }
//      }
//    }
//    &:nth-child(2) .dg-comp-teaser__item--img {
//      background: url('#{$path-img}tmp/8.svg') no-repeat;
//      background-position: 0;
//      position: relative;
//      &:after {
//        content: '';
//        position: absolute;
//        width: 50px;
//        height: 50px;
//        background: url('#{$path-img}tmp/next.svg') no-repeat;
//        background-size: contain;
//        right: -75%;
//        top: 50%;
//        transform: translateY(-50%);
//        @media (max-width: 1024px) {
//          display: none;
//        }
//      }
//    }
//    &:nth-child(3) .dg-comp-teaser__item--img {
//      background: url('#{$path-img}tmp/9.svg') no-repeat;
//      background-position: 0;
//      position: relative;
//      &:after {
//        content: '';
//        position: absolute;
//        width: 50px;
//        height: 50px;
//        background: url('#{$path-img}tmp/next.svg') no-repeat;
//        background-size: contain;
//        right: -75%;
//        top: 50%;
//        transform: translateY(-50%);
//        @media (max-width: 1024px) {
//          display: none;
//        }
//      }
//    }
//    &:nth-child(4) .dg-comp-teaser__item--img {
//      background: url('#{$path-img}tmp/10.svg') no-repeat;
//      background-position: 50% 0;
//    }
//    &--img {
//      width: 160px;
//      height: 160px;
//      margin: 0 auto 20px;
//      @media (max-width: 768px) {
//        margin: 0 auto;
//      }
//    }
//    & p {
//      @media (max-width: 768px) {
//        text-align: center;
//      }
//    }
//  }
//}
//
.dg-questions {
  margin-bottom: 60px;
  & h2 {
    text-align: center;
    font-size: rems(30);
  }
  & .dg-section {
    margin-bottom: 20px;
    cursor: pointer;
  }
  &__title {
    font-size: rems(24);
    text-decoration: none;
    display: block;
    padding: 10px 20px;
    @media (max-width: 768px) {
      font-size: rems(18);
      position: relative;
      padding: 10px 30px 10px 20px;
    }
    &:after {
      content: '';
      margin-left: 4px;
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
      background-size: contain;
      position: relative;
      top: 1px;
      @media (max-width: 768px) {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.active {
      background-color: #eceff2;
      &:after {
        transform: rotate(180deg);
        @media (max-width: 768px) {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    &--without-toggle {
      cursor: default!important;
      &:after {
        display: none;
      }
      + .dg-questions__body {
        margin-bottom: 20px;
        padding-top: 10px;
      }
    }
  }
  &__body {
    padding: 20px;
    &.hide {
      display: none;
    }
    &.active {
      display: block;
      background-color: #f7f7f7;
      border-right: 1px solid #b0bbc4;
      border-bottom: 1px solid #b0bbc4;
      border-left: 1px solid #b0bbc4;
    }
  }
}
//
//.dg-slider {
//  & h2 {
//    font-size: rems(30);
//    text-align: center;
//    @media (max-width: 768px) {
//      font-size: rems(22);
//    }
//    & span {
//      font-weight: 700;
//    }
//  }
//}
//
//.dg-order-bottom-row {
//  display: flex;
//  justify-content: space-between;
//  align-items: flex-end;
//  margin-bottom: 20px;
//  & .dg-flex-bs-50 {
//    flex-basis: 50%;
//    margin-bottom: 0;
//    @media (max-width: 768px) {
//      flex-basis: 70%;
//    }
//  }
//}
//
.dg-btn {
  &__confirm,
  &__cancel {
    font-size: rems(14);
    font-weight: 600;
    padding: 8px 20px;
    min-width: 100px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
  }
  &__confirm {
    background-color: #f75205;
    &:hover {
      background-color: #f75205+20;
    }
  }
  &__cancel {
    background-color: #5081f8;
    &:hover {
      background-color: #5081f8+20;
    }
  }

  &__row {
    margin-bottom: 20px;
    &.center {
      text-align: center;
    }
    &.margin-fix {
      margin-bottom: 20px;
    }
  }
  &__red {
    display: inline-block;
    margin: auto;
    padding: 16px 20px;
    background-color: #f75205;
    border-radius: 6px;
    color: white;
    font-size: rems(22);
    font-weight: 500;
    text-decoration: none;
    &:hover {
      background-color: #f75205+30;
    }
  }
  &__orange {
    display: inline-block;
    padding: 6px 10px;
    background-color: #ff8f07;
    border-radius: 6px;
    border: none;
    color: white;
    font-size: rems(16);
    font-weight: 500;
    text-decoration: none;
    &:hover {
      background-color: #ff8f07+30;
    }
    &.padding-fix {
      padding: 10px;
    }
    &.dg-margin-fix {
      margin: 0 auto 6px;
      padding: 10px 20px;
    }
  }
  &__transparent {
    display: inline-block;
    padding: 10px;
    margin-bottom: 4px;
    background-color: transparent;
    border: 1px solid #cecece;
    border-radius: 6px;
    color: #5f5f6d;
    font-size: rems(16);
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    &:hover {
      background-color: #cecece;
    }
  }
  &__edit {
    display: inline-block;
    width: 16px;
    height: 15px;
    background: url('#{$path-img}icons/2/edit.svg') no-repeat;
    cursor: pointer;
  }
  &__blue {
    display: inline-block;
    padding: 10px 20px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    background-color: #5081f8;
    border-radius: 6px;
    &.dg-border-fix {
      border: none;
      cursor: pointer;
    }
    &.dg-margin-auto {
      max-width: 40%;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
    &:hover {
      background-color: #5081f8+30;
    }
  }
  &__green {
    display: inline-block;
    padding: 10px 20px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    background-color: #68c44f;
    border-radius: 6px;
    &:hover {
      background-color: #68c44f+30;
    }
    &.dg-border-fix {
      border: none;
      cursor: pointer;
    }
  }
  &__back {
    border: 1px solid #ccc;
    border-radius: 20px;
    display: inline-block;
    padding: 6px 10px;
    color: #aaa;
    text-decoration: none;
    text-align: center;
    &:hover {
      color: white;
      background-color: #aaa;
      border-color: #aaa;
    }
  }
  &__test {
    display: inline-block;
    padding: 6px 10px;
    color: white;
    font-size: rems(16);
    font-weight: 600;
    text-decoration: none;
    border-radius: 6px;

    &.orange {
      background-color: #ff8f07;
      text-decoration: none;
      &:hover {
        background-color: #ff8f07+30;
      }
    }
    &.green {
      background-color: #68c44f;
      border-radius: 6px;
      &:hover {
        background-color: #68c44f+30;
      }
    }
  }
  &__pay-all {
    display: inline-block;
    padding: 10px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    background-color: #5081f8;
    border-radius: 6px;
    &:hover {
      background-color: #5081f8+20;
    }
  }
  &__pay-inst {
    display: inline-block;
    padding: 10px;
    font-weight: 600;
    text-decoration: none;
    color: #5081f8;
    border: 1px solid #5081f8;
    border-radius: 6px;
    &:hover {
      background-color: #5081f8+20;
      color: white;
    }
  }
}

.progress {
  position: relative;
  margin-bottom: 4px;
  padding: 8px 10px 9px;
  font-size: rems(16);
  color: #999;
  border-radius: 6px;
  border: 1px solid #ccc;
  & .bar {
    background-color: #47da07;
    width: 0;
    height: 20px;
  }
  & .percent {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rems(12);
    color: #666;
    font-weight: 800;
  }
}

.dg-order__add-file {
  display: block;
  margin-bottom: 4px;
  padding: 9px 10px;
  border: 1px dashed #92c0d6;
  border-radius: 6px;
  background-color: #edf3f7;
  font-size: rems(14);
  color: #457992;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #92c0d6+20;
  }
}
//
//.dg-downloaded {
//
//  &__wrapper {
//    position: relative;
//    margin-bottom: 4px;
//    padding: 10px;
//    font-size: rems(16);
//    color: #999;
//    border-radius: 6px;
//    border: 1px dashed #ccc;
//  }
//  &__del {
//    position: absolute;
//    top: 0;
//    right: 4px;
//    font-weight: bolder;
//    color: #2a2a32;
//    cursor: pointer;
//    &:hover {
//      color: #f75205;
//    }
//  }
//  &__size {
//    position: absolute;
//    font-size: rems(11);
//    bottom: -8px;
//    background: white;
//    padding: 0 10px;
//    left: 50%;
//    transform: translateX(-50%);
//  }
//  &__link {
//    display: block;
//    text-overflow: ellipsis;
//    overflow: hidden;
//    font-size: rems(12);
//    white-space: nowrap;
//  }
//}
//
//.dg-popup{
//  &.error {
//    color: white;
//    & .errorSummary {
//      padding: 10px;
//      & p {
//        font-weight: 600;
//      }
//      & ul {
//        margin: 0;
//        padding-left: 30px;
//        font-size: rems(14);
//        text-align: left;
//      }
//    }
//  }
//}
//
//
//
.dg-installment {
  &__title {
    font-size: rems(16);
    font-weight: 500;
    margin: 0 0 20px;
    background-color: #f5f7f6;
    padding: 10px;
  }
  &__wrapper {
    display: flex;
    &--section {
      width: 100%;
      padding: 0 10px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted #ddd;
    padding-bottom: 20px;
    font-size: rems(14);
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    &--basis {
      flex-basis: 49%;
    }
    & a {
      display: inline-block;
      padding: 10px;
      font-weight: 600;
      text-decoration: none;
      color: white;
      background-color: #5081f8;
      border-radius: 6px;
      @media (max-width: 768px) {
        min-width: 230px;
        text-align: center;
      }
    }
  }
  &__footer {
    & span {
      color: #f75205;
      font-size: rems(12);
    }
  }
  &.border {
    border-bottom: 1px dashed #aaa;
    margin-bottom: 20px;
  }
}

//.dg-order-payment {
//  & .dg-installment:last-child {
//    margin-bottom: 0;
//    & .dg-installment__footer {
//      margin-bottom: 0;
//    }
//  }
//}

.dg-info {
  display: inline-block;
  position: relative;
  top: -4px;
  font-size: rems(10);
  font-weight: 700;
  border-radius: 50%;
  background-color: #a1aeb8;
  padding: 1px 4px 0;
  color: #ffffff;
  &:hover {
    cursor: help;
  }
  & .dg-hide-text {
    display: none;
  }
  &:hover .dg-hide-text {
    display: block;
    position: absolute;
    padding: 20px;
    font-size: rems(12);
    border: 1px solid #f4eba7;
    border-radius: 6px;
    background-color: #fcffe6;
    color: #2a2a32;
    font-weight: 400;
    width: 400px;
    box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.1);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    @media (max-width: 1024px) {
      position: fixed;
      top: 2vh;
      width: 98vw;
      left: unset;
      right: 1vw;
      transform: translateX(0);

    }
  }
}

.dg-input {
  margin-bottom: 4px;
  padding: 10px;
  font-size: rems(16);
  color: #555;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.dg-counter__row {
  display: flex;
  justify-content: space-between;
  counter-reset: section;
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }
  &--three,
  &--one {
    position: relative;
    padding-left: 46px;
    display: flex;
    align-items: center;
    &:before {
      counter-increment: section;
      content: counter(section);
      position: absolute;
      width: 40px;
      text-align: center;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #e8ecf0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
  &--three {
    flex-basis: 32%;
    text-align: center;
    & p {
      margin: 0;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
      min-height: 42px;
    }
  }
  &--one {
    & a {
      flex-basis: 50%;
    }
  }
}
//
.dg-btn-list {
  margin: 0 0 20px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__item {
    width: 30%;
    padding-left: 50px;
    text-align: center;
    @media (max-width: 768px) {
      width: 100%;
      padding-left: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      & a {
        width: 100%;
      }
    }
  }
}
//
//.dg-our-advantages {
//  @media (max-width: 700px) {
//    display: none;
//  }
//  &__wrap {
//    display: flex;
//    justify-content: space-between;
//    & ul {
//      padding: 0;
//      margin: 0;
//      list-style-type: none;
//      & li {
//        display: block;
//        margin-bottom: 6px;
//      }
//    }
//  }
//  &__list {
//    flex-basis: 45%;
//    &--item {
//      padding: 2px 0;
//    }
//  }
//  &__link {
//    display: block;
//    padding: 16px 10px 16px 56px;
//    font-size: rems(18);
//    position: relative;
//    cursor: pointer;
//    .dg-icon-success {
//      position: absolute;
//      width: 40px;
//      height: 40px;
//      top: 50%;
//      transform: translateY(-50%);
//      left: 6px;
//      border-radius: 50%;
//      border: 1px solid;
//      &:before {
//        content: '';
//        position: absolute;
//        width: 7px;
//        border: 2px solid;
//        top: 50%;
//        transform: translate(60%, 10%) rotate(45deg);
//      }
//      &:after {
//        content: '';
//        position: absolute;
//        width: 16px;
//        border: 2px solid;
//        top: 50%;
//        transform: translate(65%) rotate(-45deg);
//      }
//    }
//    .dg-icon-success,
//    .dg-icon-success:before,
//    .dg-icon-success:after {
//      border-color: #aaa;
//    }
//    &.active {
//      background-color: #eee;
//      .dg-icon-success,
//      .dg-icon-success:before,
//      .dg-icon-success:after {
//        border-color: #555;
//      }
//      clip-path: polygon(97% 0, 0 0, 0 100%, 97% 100%, 100% 50%);
//    }
//    &:hover {
//      background-color: #eee;
//      .dg-icon-success,
//      .dg-icon-success:before,
//      .dg-icon-success:after {
//        border-color: #555;
//      }
//      clip-path: polygon(97% 0, 0 0, 0 100%, 97% 100%, 100% 50%);
//    }
//
//  }
//  &__show-list {
//    flex-basis: 50%;
//    &--item {
//      & p {
//        font-size: rems(18);
//        margin-bottom: 30px;
//      }
//      & .dg-yellow-block {
//        padding: 20px;
//        border-radius: 4px;
//        border: 2px solid #f9e8aa;
//        background-color: #fffae9;
//        margin-bottom: 30px;
//        display: flex;
//        justify-content: space-between;
//        color: black;
//        text-decoration: none;
//        &.dg-shadow {
//          box-shadow: 4px 8px 20px rgba(0,0,0,.1);
//          &:hover {
//            box-shadow: 6px 10px 22px rgba(0,0,0,.2);
//          }
//        }
//        &__ls,
//        &__rs {
//          flex-basis: 45%;
//          & strong {
//            font-size: rems(20);
//          }
//          & span {
//            display: block;
//          }
//        }
//        &__vert-line {
//          width: 1px;
//          background-color: #555;
//        }
//      }
//      & .dg-gray-block {
//        padding: 20px;
//        font-size: rems(18);
//        background-color: #eee;
//        margin-bottom: 10px;
//      }
//      &.hide {
//        display: none;
//      }
//      &.show {
//        display: block;
//        & p {
//          padding: 0 20px;
//        }
//      }
//      & .dg-flex-table {
//        display: flex;
//        justify-content: space-around;
//        &__ls,
//        &__rs {
//          padding: 10px;
//        }
//        &__header {
//          font-size: rems(18);
//          color: #ccc;
//          display: block;
//          margin-bottom: 6px;
//        }
//        &__body {
//          & li {
//            text-align: center;
//          }
//        }
//      }
//    }
//  }
//}
//.dg-icon-mail {
//  display: block;
//  position: relative;
//  &:after {
//    content: '';
//    position: absolute;
//    right: 0;
//    top: 0;
//    width: 200px;
//    height: 120px;
//    background: url('#{$path-img}icons/mail.svg') no-repeat;
//  }
//}
//
//.dg-relative {
//  position: relative;
//  margin-bottom: 20px;
//}
//
//.dg-icon-money-back {
//  display: block;
//  position: relative;
//  &:after {
//    content: '';
//    position: absolute;
//    left: 50%;
//    transform: translateX(-50%);
//    top: 0;
//    width: 260px;
//    height: 210px;
//    background: url('#{$path-img}icons/money-back-b.svg') no-repeat;
//  }
//}
//
//.kr-specialist-block {
//  display: none;
//}
//

//
//.dg-issued-bg {
//  //background: white url('#{$path-img}tmp/issued-bg.png') repeat;
//  padding: 40px 0 50px;
//  @media (max-width: 600px) {
//    padding: 40px 0 0;
//  }
//}
//
//.dg-gray-l-bg {
//  background-color: #eee;
//  padding: 60px 0;
//}
//
//
//.dg-issued-docs {
//  & h2 {
//    text-align: center;
//    margin: 0 0 20px;
//    text-transform: uppercase;
//  }
//  &__title {
//    font-size: rems(24);
//    font-weight: 600;
//    display: block;
//  }
//  &__list {
//    margin: 0;
//    padding: 0;
//    list-style-type: none;
//    &--item {
//      display: flex;
//      text-align: center;
//      background: url('#{$path-img}icons/herb.svg') no-repeat;
//      &:nth-child(2n - 1) {
//        background-position: 25%;
//      }
//      &:nth-child(2n) {
//        background-position: 75%;
//      }
//      & .text-wrapper {
//        width: 30%;
//        display: flex;
//        flex-direction: column;
//        & p {
//          margin: auto 0;
//          font-size: rems(18);
//          line-height: 1.5;
//          text-align: left;
//          & .bold {
//            font-weight: 600;
//            display: block;
//            margin-bottom: 20px;
//          }
//        }
//      }
//      & .img-wrapper {
//        max-width: 70%;
//        @media (max-width: 1000px) {
//          max-width: 100%;
//        }
//      }
//      @media (max-width: 1000px) {
//        flex-direction: column;
//        align-items: center;
//        & .text-wrapper {
//          width: 100%;
//          &:first-child {
//            transform: translateY(0);
//          }
//        }
//      }
//    }
//  }
//}
//
//.dg-course-content {
//  margin-bottom: 40px;
//  & p,
//  & table {
//    margin: 0 0 20px;
//  }
//}
//
//.dg-about-org {
//  font-size: rems(14);
//  text-align: left;
//  th, td {
//    padding: 10px;
//    vertical-align: top;
//  }
//}
//
//.d-course-pay-type {
//
//}
//
.dg-docs-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  & li {
    margin: 20px;
    & a {
      color: inherit;
      display: block;
      padding: 10px 20px;
      background-color: #efefef;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        background-color: #ff9200;
        padding: 10px 20px 10px 40px;
        transition: .1s;
      }
    }
  }
}

/*

::-webkit-scrollbar-button {
  background-image:url('');
  background-repeat:no-repeat;
  width:5px;
  height:0;
}

::-webkit-scrollbar-track {
  background-color:#ecedee
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color:#6dc0c8;
}

::-webkit-scrollbar-thumb:hover{
  background-color:#56999f;
}

::-webkit-resizer{
  background-image:url('');
  background-repeat:no-repeat;
  width:4px;
  height:0;
}

::-webkit-scrollbar{
  width: 4px;
}

*/

.dg-test {
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccd2d7;
  overflow: hidden;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  &__title {
    margin: 0;
    font-size: rems(26);
    flex-basis: 75%;
    @media (max-width: 768px) {
      font-size: 4vw;
      flex-basis: unset;
    }
  }
  &__date-end {
    margin-bottom: 20px;
    font-size: rems(14);
    color: #666;
    text-align: right;
  }
  &__back {
    text-align: right;
    @media (max-width: 768px) {
      display: none;
    }
    &--link {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff8f07;
      border-radius: 4px;
      color: white;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        background-color: #ffa31b;
      }
    }
  }
  &__alert {
    background-color: rgba(255,235,59,.2);
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #EACB00;
    & p {
      &:last-child {
        margin: 0;
      }
    }
  }
  &__module-title {
    background-color: #f0f3f6;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &:first-child {
      border-radius: 10px 10px 0 0;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    & h2 {
      font-size: rems(18);
      font-weight: 600;
      margin: 0;
      flex-basis: 90%;
      &.passed:after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 4px;
        background: url('#{$path-img}icons/2/paid.svg') no-repeat;
        transform: translateY(20%);
      }
    }
    & .hider {
      white-space: nowrap;
      &:after {
        content: '';
        margin-left: 4px;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('#{$path-img}tmp/podrobnee.svg') no-repeat;
        background-size: contain;
        position: relative;
        top: 4px;
        transform: rotate(0deg);
      }
    }
    &.dg-disable {
      margin-bottom: 20px;
      background-color: #eee;
      border-radius: 10px;
      border: 1px solid #ccd2d7;
    }
  }
  &__module {
    background-color: white;
    padding: 10px 10px 0;
    &:last-child {
      border-radius: 0 0 10px 10px;
      padding: 10px 10px 10px;
    }
    &--header {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      align-items: center;
      margin-bottom: 10px;
      & h4 {
        flex-basis: 75%;
        margin: 0;
      }
      & .dg-passed {
        &:after {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('#{$path-img}icons/2/paid.svg') no-repeat;
          transform: translateY(20%);
        }
      }
    }
    &--section {
      display: flex;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      & .dg-ls,
      & .dg-rs {
        flex-basis: 100%;
      }
      & .dg-ls {
        padding: 10px;
        border-radius: 10px 0 0 10px;
        border-top: 1px solid #ccd2d7;
        border-bottom: 1px solid #ccd2d7;
        border-left: 1px solid #ccd2d7;
        @media (max-width: 768px) {
          border-radius: 10px 10px 0 0;
          border-bottom: none;
          border-right: 1px solid #ccd2d7;
        }

        & iframe {
          display: block;
          width: 100%;
        }
        & a {
          word-break: break-all;
          display: inline-block;
        }
      }
      & .dg-rs {
        padding: 10px;
        border-radius: 0 10px 10px 0;
        border: 1px solid #ccd2d7;
        @media (max-width: 768px) {
          border-radius: 0 0 10px 10px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.padding-fix {
      padding: 20px 20px 10px;
    }
    // spike!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    & .dg-table-wrapper {
      @media (max-width: 768px) {
        overflow-x: scroll;
      }
    }
    & table {
      border-collapse: collapse;
      width: 100%;
      min-width: 768px;
      & th,
      & td {
        border-right: 1px solid #eee;
        padding: 10px;
        min-width: 10%;
        &:last-child {
          border: none;
        }
      }
      & td {
        text-align: left;
        font-size: rems(14);
      }
      & th {
        text-align: center;
        background-color: #eee;
      }
    }
    &--final {
      padding: 20px 0;
      margin-bottom: 10px;
      font-size: rems(18);
      & .bold {
        font-weight: 500;
      }
      & .marked {
        font-weight: 600;
        color: #69c258;
      }
    }
    // spike!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  }
  &__result {
    margin-bottom: 10px;
    &--text {

    }
    &--value {
      display: inline-block;
      padding: 2px 10px 4px;
      border-radius: 20px;
      color: white;
      line-height: 1;
      font-weight: 600;
      &.red {
        background-color: #f75205;
      }
      &.green {
        background-color: #69c258;
      }
    }
  }
  &__grade {
    margin-bottom: 10px;
    &--text {

    }
    &--value {
      display: inline-block;
      padding: 2px 10px 4px;
      border-radius: 20px;
      color: white;
      line-height: 1;
      font-weight: 600;
      &.red {
        color: #f75205;
      }
      &.green {
        color: #69c258;
      }
    }
  }

  &__popup {
    background-color: white;
    &--header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background-color: #f0f3f5;
      align-items: center;
      & h3 {
        margin: 0;
        text-align: left;
      }
      & .close {
        font-size: rems(40);
        color: black;
        font-weight: 100;
        cursor: pointer;
        &:hover {
          color: #f75205;
        }
      }
    }
    &--row {
      padding: 0 20px 20px;
      text-align: center;
      background-color: white;
      & .dg-btn {
        display: inline-block;
        padding: 10px 20px;
        font-weight: 600;
        border: none;
        background-color: #5081f8;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        &:hover {
          background-color: #6e9fff;
        }
      }
    }
    &--content {
      padding: 20px;
      & img {
        max-width: unset;
        height: unset;
      }
      & p:last-child {
        margin-bottom: 0;
      }
      & table {
        max-width: 100%;
        display: block;
      }
    }
  }
  &.hide {
    & .dg-test__module {
      display: none;
    }
    & .hider:after {
      transform: rotate(-90deg);
    }
  }
  & .dg-small {
    font-size: rems(12);
    line-height: 1.3;
    color: #aaa;
  }
  &__center {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  &__ou {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #ccd2d7;
    overflow: hidden;
    background-color: white;
    & .dg-small {
      font-size: rems(12);
      line-height: 1.3;
      color: #aaa;
    }
    &--row {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rems(16);
      margin-bottom: 10px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      & input {
        padding: 6px 10px;
        font-size: rems(16);
        color: #555;
        border-radius: 6px;
        border: 1px solid #ccc;
        margin-right: 20px;
        flex-basis: 70%;
      }
      & .dg-title {
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
  &.bg-fixed {
    background-color: white;
  }
  &__upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--wrapper {
      flex-basis: 50%;
      margin-right: 10px;
    }
    &--text {
      font-weight: 600;
    }
    &--name {

    }
    &--status {
      & .gray,
      & .red,
      & .green {
        display: inline-block;
        padding: 2px 10px;
        border-radius: 20px;
        color: white;
        line-height: 1.1;
        font-weight: 600;
        white-space: nowrap;
      }

      & .gray {
        background-color: #aaa;
      }
      & .red {
        background-color: #f75205;
      }
      & .green {
        background-color: #69c258;
      }

    }
  }
}

.dg-disabled-tip {
  color: #aaa;
  &:after {
    content: '';
    display: inline-block;
    position: relative;
    top: 4px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
    background: url('#{$path-img}icons/2/paid.svg') no-repeat;
  }
}

.dg-messages {
  padding: 0;
  margin: 0;
  list-style-type: none;
  &--item {
    padding: 20px;
    background-color: rgba(255,255,255,.4);
    border-radius: 10px;
    margin-bottom: 20px;
  }
}

.dg-choice-pay {
  &__side {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px dashed #ccc;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
    &--text {
      flex-basis: 75%;
      @media (max-width: 768px) {
        flex-basis: 100%;
        margin-bottom: 10px;
      }
    }
    &--btn {
      display: inline-block;
      padding: 10px;
      font-weight: 600;
      text-decoration: none;
      color: white;
      background-color: #5081f8;
      border-radius: 6px;
      min-width: 200px;
      text-align: center;
      &:hover {
        background-color: #6495ff;
      }
    }
  }
}

.dg-installment-popup {
  h4 {
    color: #4d6478;
  }
  padding-bottom: 10px;
}

.dg-installment-tease {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__item {
    padding: 20px;
    border: 1px solid #4d6478;
    border-radius: 10px;
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 80%;
      margin: 0 auto 20px;

    }
    &--title {
      font-weight: 500;
      margin-bottom: 20px;
      font-size: rems(18);
      text-align: center;
      color: #4d6478;
    }
  }
}

.dg-course__about--pager {
  .dg-section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  h4:not(:last-child),
  p:not(:last-child) {
    margin-bottom: 5px;
  }
}

.dg-course-popup {
  display: flex;
  &__ls {
    flex-basis: 70%;
    margin-right: 20px;
    & .dg-vertical {
      margin-bottom: 20px;
    }
    & .dg-popup-btn {
      padding: 10px 30px;
      border-radius: 6px;
      color: white;
      background-color: #35678c;
      font-size: rems(18);
      border: 0;
      cursor: pointer;
      width: unset;
      text-decoration: none;
      &:hover {
        background-color: #497ba0;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &__rs {
    flex-basis: 30%;
    margin: -20px -20px -2px 0;
    padding: 20px;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #27262f;
    & .dg-sale {
      padding: 20px;
      box-shadow: inset 0 0 0 6px #3d3b43;
      background-color: #545258;
      line-height: 1.1;
      &__header {
        display: block;
        text-align: center;
        font-size: rems(32);
        font-weight: 800;
      }
      &__body {
        display: block;
        text-align: center;
        font-size: rems(74);
      }
      &__footer {
        display: block;
        text-align: center;
        font-size: rems(16);
        font-weight: 400;
      }
    }
  }
}


.nowrap {
  white-space: nowrap;
}

.dg-button-section {
  text-align: right;
}

//.dg-attention {
//  color: red;
//  background-color: #fff995;
//  padding-bottom: 10px;
//  font-weight: 600;
//  font-size: rems(20);
//  line-height: 1;
//  position: fixed;
//  width: 100%;
//  z-index: 99;
//  &__stroke {
//    display: inline-block;
//    border-bottom: 2px solid #2a2a32;
//  }
//}

//.dg-cost {
//  color: red;
//}
//.st_section--link{
//  margin-left: auto;
//  font-size: 14px;
//  color: #666;
//  font-weight: 300;
//  &:hover {
//    color: #666 + 20;
//  }
//  @media (max-width:768px) {
//  margin-top: 20px;
//  margin-left: 0;
//  }
//}
//.st_download-link {
//  font-size: 14px;
//  color: #666;
//  font-weight: 300;
//  &:hover {
//    color: #666 + 20;
//  }
//  @media (max-width: 768px) {
//    &:first-child {
//      margin-bottom: 10px;
//    }
//  }
//}


//.st_sale{
//  &--button {
//    margin: auto 0;
//    display: inline-block;
//    padding: 16px 20px;
//    background-color: #f75205;
//    border-radius: 6px;
//    color: white;
//    font-size: 1.275rem;
//    font-weight: 500;
//    text-decoration: none;
//  }
//}


.st-input {
  &__center {
    margin: 0 auto;
  }
  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: left;
    & label {
      margin-bottom: 6px;
      font-weight: 500;
    }
    & input {
      padding: 10px;
      font-size: 1rem;
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
    }
    & textarea {
      font-family: 'Montserrat', 'Open Sans', Arial, Helvetica, sans-serif;
      flex-grow: 1;
      padding: 10px;
      font-size: 0.875rem;
      color: #555;
      border-radius: 6px;
      border: 1px solid #ccc;
    }
  }
  &__btn-row {
    display: flex;
    justify-content: center;
  }
  &__btn {
    padding: 10px 30px;
    border-radius: 6px;
    color: white;
    background-color: #35678c;
    font-size: 1.125rem;
    border: 0;
    cursor: pointer;
  }
}


.teaching-staff {
  padding: 30px;
  border-radius: 4px;
  background-color: #fff;

  &__title {
    margin-bottom: 26px;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.2;
  }

  &__item {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    @media (max-width: 640px) {
      display: block;
    }
  }

  &__pic {
    flex-shrink: 0;
    margin-right: 20px;
    width: 130px;
    height: 160px;
    overflow: hidden;
    @media (max-width: 640px) {
      margin-bottom: 10px;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__content {
    flex-grow: 1;

    ul {
      margin-bottom: 10px;
      margin-left: 18px;
      list-style-type: disc;
    }
  }
}