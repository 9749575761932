@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

.dg-footer {
  margin-top: auto;
  background-color: #1e259d;
  color: #eee;
  padding: 20px 0;
  //max-height: 315px;
  &__wrapper {
    max-width: 1260px;
    padding: 0 10px;
    margin: 0 auto;
  }
  & .dg-flex {
    display: flex;
    margin-bottom: 20px;
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    &__bottom {
      text-align: center;
      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__left-side {
    width: 33.3%;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      width: 100%;
      order: 3;
    }
    & .dg-logo {
      text-align: center;
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      font-size: rems(18);
      margin-bottom: 10px;
      @media (max-width: 1024px) {
        display: none;
      }
      &__img {
        width: 70px;
        margin-right: 10px;
      }
      & span {

      }
    }
    & .dg-license,
    & .dg-contacts {
      font-size: rems(14);
      font-weight: 300;
      & i {
        font-style: normal;
        color: #8589d8;
      }
    }
    & .dg-license {
      padding-left: 60px;
      background: url("#{$path-img}icons/gerb.png") no-repeat;
      background-size: contain;
      margin-bottom: 30px;
    }
    & .dg-contacts {
      display: flex;
      flex-direction: column;
      &__mail a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }


  &__right-side {
    //display: flex;
    //justify-content: space-between;
    //width: 66.6%;
    &--menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;
      padding: 0 40px;
      @media (max-width: 1000px) {
        width: 50%;
        padding: 0 20px 20px 0 ;
      }
      @media (max-width: 600px) {
        width: 60%;
      }
      & ul {
        margin: auto;
        width: 100%;
        padding: 0 15%;
        list-style: none;
        border-right: 1px solid white;
        border-left: 1px solid white;
        @media (max-width: 1000px) {
          border-left: none;
        }
        @media (max-width: 600px) {
          padding: 0 15px 0 0;
        }
        & li {
          padding-bottom: 12px;
          &:last-child {
            padding: 0;
          }
          & a {
            color: white;
            font-size: rems(14);
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    &--text {
      color: #8589d8;
      width: 45%;
      @media (max-width: 990px) {
        display: none;
      }
    }
    &--message {
      width: 35%;
      display: flex;
      align-items: center;
      @media (max-width: 1000px) {
        width: 50%;
        padding-bottom: 20px;
      }
      @media (max-width: 600px) {
        width: 40%;
        justify-content: center;
      }
      & a {
        margin: auto;
        font-size: rems(16);
        text-decoration: none;
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
      & .dg-license__link {
        display: flex;
        flex-direction: column;
        & img {
          max-width: 88px;
          margin: 0 auto;
        }
      }
    }
  }
  & .dg-terms {
    &__user-ag,
    &__polite {
      padding: 10px;
      color: #eee;
      font-size: rems(14);
      white-space: nowrap;
      &:hover {
        color: white;
      }
    }
  }
}


