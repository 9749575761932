$path-img: '../img/';

@function rems($pxs) {
  @return ($pxs / 16) + rem;
}

@keyframes aitf {
  0% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.st-header {
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 9999;
  box-shadow: 0 1px 10px rgba(0,0,0, .4);
  margin-bottom: 20px;
  &__main {
    display: flex;
    padding: 10px 0;
    width: 100%;
    @media (max-width: 560px) {
      padding: 15px 0;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    @media (max-width: 560px) {
      margin-right: 0;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #060d83;
    text-decoration: none;
    @media (max-width: 1024px) {
      & span {
        display: none;
      }
    }
    @media (max-width: 480px) {
      width: 19%;
      margin-right: 5px;
    }
    & span {
      background: url('#{$path-img}/new/tmp/text_fill.png') repeat-y;
      background-clip: text;
      animation: aitf 80s linear infinite;
      transform: translate3d(0,0,0);
      backface-visibility: hidden;
    }
    & img {
      vertical-align: bottom;
      margin-right: 10px;
      @media (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
  &__phone {
    font-size: 22px;
    color: #060d83;

    span {
      display: block;
    }

    @media (max-width: 1100px) {
      font-size: 20px;
    }
    @media (min-width: 1025px) {
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      font-size: 18px;
    }
    @media (max-width: 600px) {

      span {
        display: none;
      }

    }
  }
  &__login {
    min-width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .dg-login {
      padding: 8px 26px;
      font-size: 0.875rem;
      border-radius: 6px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #060d83;
      color: #060d83;
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 768px) {
          margin-right: 5px;
        }
      }
      @media (max-width: 480px) {
        padding: 5px 20px;
      }
    }
    @media (max-width: 768px) {
      flex-shrink: 0;
      min-width: auto;
    }
  }
}

.st-header__login {
  .kr-mobile-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    color: #060d83;
    border-radius: 6px;
    padding: 6px 16px 6px 10px;
    white-space: nowrap;
    min-width: 200px;
    max-width: 200px;
    z-index: 100;
    & img {
      width: 30px;
      margin-right: 6px;
      border-radius: 50%;
    }
    & .dg-name {
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      max-width: 200px;
      vertical-align: sub;
      cursor: context-menu;
      text-overflow: ellipsis;
      color: #060d83;
      &:after {
        font-weight: 700;
        content: '‹';
        color: #060d83;
        position: absolute;
        right: 5px;
        transform: rotate(-90deg);
        font-size: 32px;
        line-height: 25px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .dg-nav {
      display: none;
      position: absolute;
    }
    @media (max-width: 768px) {
      min-width: unset;
    }
    &:hover {
      @media (min-width: 769px) {
        .dg-nav {
          display: block;
          position: absolute;
          left: 0;
          top: 60%;
          margin: 0;
          padding: 18px 0 0 0;
          width: 100%;
          list-style: none;
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 6px;
          //  left: 50%;
          //  transform: translateX(-50%);
          //  border-left: 10px solid transparent;
          //  border-top: 0 solid transparent;
          //  border-right: 10px solid transparent;
          //  border-bottom: 12px solid white;
          //}
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 18px;
            right: 3px;
            bottom: 3px;
            left: 3px;
            box-shadow: 0 6px 16px 0 rgba(0,0,0,.4);
            z-index: -1;
          }
          & li {
            margin: 0;
            background-color: white;
            overflow: hidden;
            &:first-child {
              border-radius: 6px 6px 0 0;
            }
            &:last-child {
              border-radius: 0 0 6px 6px;
            }
            & a {
              padding: 10px 20px 10px;
              display: block;
              text-decoration: none;
              color: #060d83;
              &:hover {
                background-color: #ebecff;
              }
            }
          }
        }
      }
    }
  }
}

.dg-system-message {
  position: relative;
  display: flex;
  @media (max-width: 1024px) and (min-width: 560px){
    margin-left: auto;
  }
  &__icon {
    width: 20px;
    margin: auto 10px;
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;
    &--value {
      z-index: 20;
      position: absolute;
      min-width: 16px;
      text-align: center;
      line-height: 1;
      top: -50%;
      right: -50%;
      font-size: rems(14);
      color: white;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: rems(18);
        width: rems(18);
        background-color: #060d83;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
  &__wrapper {
    display: none;
    position: absolute;
    margin-top: 89%;
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: 6px;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  border-left: 14px solid transparent;
    //  border-top: 0 solid transparent;
    //  border-right: 14px solid transparent;
    //  border-bottom: 16px solid white;
    //}
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 18px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.4);
      z-index: -1;
    }
    @media (max-width: 1024px) and (min-width: 601px) {
      right: 0;
      left: unset;
      transform: translateX(2%);
      //&:before {
      //  left: unset;
      //  right: 0;
      //}
    }
    @media (max-width: 600px) {
      top: 100%;
      left: 100%;
      max-width: unset;
      width: 90vw;
      margin-top: 0;
    }
    &.show {
      display: block;
    }
  }
  &__inner {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    min-width: 400px;
    @media (max-width: 600px) {
      min-width: unset;
    }

    &--list {
      list-style-type: none;
      margin: 0;
      padding: 0 4px;
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ff9200;
      }
      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 24px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        box-shadow: 0 6px 16px 0 rgba(0,0,0,.3);
        z-index: -1;
      }
    }
  }
  &__item {
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid #eee;
    position: relative;
    font-size: rems(14);
    line-height: 1.3;
    &:last-child {
      border-bottom: none;
    }
    &--del {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
      font-size: rems(30);
      color: #666;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  &__unscroll {
    padding: 10px 0 2px;
    text-align: center;
    border-top: 1px solid #eee;
    &--btn {
      display: inline-block;
      padding: 6px 20px;
      border-radius: 4px;
      color: white;
      text-decoration: none;
      font-weight: 600;
      background-color: #ff8f07;
    }
  }
}

// mobile menu

#toggle-search,
#toggle-menu {
  display: none;
}

.close {
  cursor: pointer;
}

.toggle-menu-container {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  & .close {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid #060d83;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 26px;
      left: 0;
      height: 2px;
      background-color: #060d83;
    }
    &:before {
      top: 20%;
    }
    &:after {
      top: 62%;
    }
  }
}

#toggle-menu:checked {
  & ~ .dg-nav {
    display: block;
    top: 50px;
    right: 0;
    background-color: white;
    margin: 0;
    padding: 20px;
    list-style-type: none;
    border-radius: 10px;
    box-shadow: 0 6px 16px -4px rgba(0,0,0,.3);
    & li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      & a {
        text-decoration: none;
        color: #2a2a32;
      }
    }
  }
  & ~ .toggle-menu-container {
    & .close {
      border-bottom: none;
      transition: ease-out 0.5s;
      &:before,
      &:after {
        top: 50%;
        left: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 38px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-top: 0 solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid white;
    }
  }
}

//.dg-header {
//  position: fixed;
//  top: 0;
//  left: 0;
//  right: 0;
//  z-index: 999;
//  background-color: $color-main-blue;
//  padding: 10px 0;
//  &__logo {
//    @media (max-width: 768px) {
//      flex-basis: 50%;
//      margin-bottom: 10px;
//    }
//    &--link {
//      height: 40px;
//      background: url("#{$path-img}icons/logo2.svg") no-repeat;
//      padding-left: 64px;
//      color: white;
//      text-decoration: none;
//      display: flex;
//      flex-direction: column;
//      line-height: 18px;
//      @media (max-width: 360px) {
//        padding: 64px 0 0 0;
//        background-position: 50% 0;
//        margin-bottom: 50px;
//      }
//    }
//    &--text {
//      font-size: rems(18);
//      font-weight: 300;
//      margin: auto 0;
//    }
//    &--tel {
//      font-size: rems(12);
//      font-weight: 400;
//      text-align: center;
//      @media (max-width: 360px) {
//        padding: 10px 0;
//
//      }
//    }
//  }
//  &__search {
//    position: relative;
//    flex-grow: 3;
//    padding: 0 30px;
//    @media (max-width: 768px) {
//      position: unset;
//      display: none;
//    }
//    & form {
//      width: 100%;
//      & fieldset {
//        border: none;
//        padding: 0;
//        position: relative;
//        & .js-term {
//          border: none;
//          border-radius: 6px;
//          font-size: rems(16);
//          font-weight: 300;
//          color: #555;
//          padding: 12px 50px 12px 12px;
//          height: 42px; // for MOZ
//          width: 100%;
//        }
//        & button {
//          width: 50px;
//          height: 42px;
//          position: absolute;
//          top: 0;
//          right: -2px;
//          border: none;
//          border-radius: 6px;
//          background-color: #a1aeb8;
//          text-align: center;
//          cursor: pointer;
//          & span {
//            position: absolute;
//            top: 50%;
//            right: 50%;
//            transform: translate(50%, -44%);
//            display: inline-block;
//            width: 18px;
//            height: 18px;
//            background: url("#{$path-img}icons/search.svg") no-repeat;
//            line-height: 36px;
//          }
//        }
//      }
//    }
//    &--mobile {
//      display: none;
//    }
//
//  } // search
//
//  &__phone {
//    color: #eee;
//    font-weight: 300;
//    padding-left: 42px;
//    background: url("#{$path-img}icons/telephone.svg") no-repeat;
//    line-height: 1.2;
//    margin: 0 20px;
//    display: flex;
//    flex-direction: column;
//    & span {
//      white-space: nowrap;
//    }
//    & .free {
//      font-size: rems(14);
//    }
//  }
//  &__login {
//    & .dg-login {
//      padding: 8px 16px;
//      color: white;
//      font-size: rems(14);
//      border-radius: 6px;
//      display: inline-block;
//      cursor: pointer;
//      &__icon {
//        display: inline-block;
//        width: 20px;
//        height: 20px;
//        margin-left: 16px;
//        background: url("#{$path-img}icons/enter.svg") no-repeat;
//        vertical-align: bottom;
//      }
//    }
//  }
//
//  .dg-flex {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    max-width: 1260px;
//    padding: 10px;
//    margin: 0 auto;
//  }
//}

//.dg-mobile-m {
//  display: flex;
//  justify-content: space-between;
//  flex-basis: 68%;
//  @media (max-width: 1024px) and (min-width: 501px) {
//    flex-basis: 50%;
//    justify-content: flex-end;
//  }
//  @media (max-width: 500px) {
//    flex-basis: 100%;
//  }
//}



