$max-width: 1218px;


$container: 768px;
$container-gutters: 30px;

$col-brake: 768px;
$col-gutters: 30px;
$gutter: #{$col-gutters} / 2;


/*
**        BODY-FLEX
 */


html {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  & footer.footer {
    width: 100%;
    margin: auto 0 0;
  }
}

/*
**        CONTAINER
**/

.container {
  display: flex;
  justify-content: space-between;
  max-width: $max-width;
  margin: 0 auto;
  @for $l from 1 through 12 {
    & > .column-#{$l} {
      flex-basis: calc( (#{$l}/12) * (100% - 22 * #{$gutter}) + (#{$l} - 1) * 2 *#{$gutter} );
      max-width: calc( (#{$l}/12) * (100% - 22 * #{$gutter}) + (#{$l} - 1) * 2 *#{$gutter} );
      &:nth-child(n + 2) {
        margin-left: #{$container-gutters};
      }
    }
  }
  @media (max-width: $container) {
    flex-direction: column;
  }
  @media (max-width: 1218px) {
    padding: 0 10px;
  }
  & > div {
    @media (max-width: $container) {
      flex-basis: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-bottom: $container-gutters;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*
**        INNER ROWS
**/

.box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: $col-brake) {
    flex-direction: column;
  }
  //.col-x
  @for $i from 1 through 12 {

    & .col-#{$i} {
      flex-basis: calc( (#{$i}/12) * (100% - 22 * #{$gutter}) + (#{$i} - 1) * 2 *#{$gutter} );
      max-width: calc( (#{$i}/12) * (100% - 22 * #{$gutter}) + (#{$i} - 1) * 2 *#{$gutter} );
      &:nth-child(n + 2) {
        margin-left: #{$col-gutters};
      }
    }
  }
  & > * {
    @media (max-width: $col-brake) {
      flex-basis: 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      //margin-bottom: $col-gutters;
      //&:last-child {
      //  margin-bottom: 0;
      //}
    }
    &.pull-right {
      margin-left: auto !important;
    }
  }

  //.col-x without margins
  &__wm {
    display: flex;
    @for $i from 1 through 12 {
      & .col-#{$i} {
        flex-basis: calc(100% * #{$i} / 12);
        margin-left: 0!important;
      }
    }
    @media (max-width: $col-brake) {
      flex-wrap: wrap;
    }
    & > div {
      @media (max-width: $col-brake) {
        flex-basis: 100% !important;
        max-width: 100%;
        //margin-bottom: $col-gutters;
        //&:last-child {
        //  margin-bottom: 0;
        //}
      }
    }
    & .pull-right {
      margin-left: auto !important;
    }
  }

  //.cols vertical position
  &.flex-start {
    align-items: flex-start;
    @media (max-width: $col-brake) {
      align-items: stretch;
    }
  }
  &.flex-end {
    align-items: flex-end;
    @media (max-width: $col-brake) {
      align-items: stretch;
    }
  }
  &.flex-center {
    align-items: center;
    @media (max-width: $col-brake) {
      align-items: stretch;
    }
  }
}

.vertical-center {
  display: flex;
  height: 100%;
  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.center {
  display: flex;
  height: 100%;
  & > * {
    margin: auto;
  }
}

.main {
  &__wrapper {
    max-width: 1218px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 1240px) {
      padding: 0 10px;
    }
  }
}